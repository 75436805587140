import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import {
  actions,
  clientAssignModulesType,
  clientInfoType,
  getAssignedModuleType,
  modulesType,
} from '../../actions/Types'
import {
  ADD_ASSIGNED_MODULES,
  ASSIGN_MODULE,
  ASSIGN_SUB_MODULE,
  CREATE_CLIENTS,
  DELETE_CLIENT,
  EDIT_CLIENT,
  FEATURE_IDS,
  GET_ALL_CLIENTS,
  GET_CLIENT_FEATURES,
  GET_CLIENT_MODULES,
  GET_SUB_MODULE,
  MERG_SELECTED_MODULE,
  REMOVE_ASSIGNED_MODULE,
  RESET,
  RESET_ASSIGNED_MODULES,
  RESET_CLIENT_INFO,
  RESET_SELECTED_MODULE,
  SET_CLINET_INFO,
  UPDATE_CLIENT,
} from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST } = actionTypes

const entity = () => {
  const getAllClients = (state = [], action: actions) => {
    switch (action.type) {
      case GET_ALL_CLIENTS[SUCCESS]:
        return action.payload
      case GET_ALL_CLIENTS[RESET]:
        return []
      default:
        return state
    }
  }

  const createClients = (state = [], action: actions) => {
    switch (action.type) {
      case CREATE_CLIENTS[SUCCESS]:
        return action.payload
      case CREATE_CLIENTS[REQUEST]:
        return []
      default:
        return state
    }
  }

  const editClient = (state = [], action: actions) => {
    switch (action.type) {
      case EDIT_CLIENT[SUCCESS]:
        return action.payload
      case EDIT_CLIENT[REQUEST]:
        return []
      case EDIT_CLIENT[RESET]:
        return []
      default:
        return state
    }
  }

  const deleteClient = (state = [], action: actions) => {
    switch (action.type) {
      case DELETE_CLIENT[SUCCESS]:
        return action.payload
      case DELETE_CLIENT[REQUEST]:
        return []
      default:
        return state
    }
  }

  const assignModule = (state = [], action: actions) => {
    switch (action.type) {
      case ASSIGN_MODULE[SUCCESS]:
        return action.payload
      case ASSIGN_MODULE[REQUEST]:
        return []
      default:
        return state
    }
  }

  const assignSubModule = (state = [], action: actions) => {
    switch (action.type) {
      case ASSIGN_SUB_MODULE[SUCCESS]:
        return action.payload
      case ASSIGN_SUB_MODULE[REQUEST]:
        return []
      default:
        return state
    }
  }

  const updateClient = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_CLIENT[SUCCESS]:
        return action.payload
      case UPDATE_CLIENT[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getClientModules = (state = [], action: actions) => {
    switch (action.type) {
      case GET_CLIENT_MODULES[SUCCESS]:
        return action.payload
      case GET_CLIENT_MODULES[REQUEST]:
        return []
      case GET_CLIENT_MODULES[RESET]:
        return []
      case GET_CLIENT_MODULES[RESET]:
        return []
      default:
        return state
    }
  }

  const clientAssignModules = (state: modulesType[] = [], action: clientAssignModulesType) => {
    switch (action.type) {
      case ADD_ASSIGNED_MODULES:
        state = action.data
        return state
      case RESET_ASSIGNED_MODULES:
        state = []
        return []
      default:
        return state
    }
  }

  const getAssignedModules = (state: modulesType[] = [], action: getAssignedModuleType) => {
    switch (action.type) {
      case MERG_SELECTED_MODULE:
        state = action.data
        return state
      case REMOVE_ASSIGNED_MODULE:
        const removeModule: any = action.data
        state = state.filter((module: modulesType) => {
          return module.code !== removeModule.code
        })
        return state
      case RESET_SELECTED_MODULE:
        state = []
        return state
      default:
        return state
    }
  }

  const getClientInfo = (
    state: clientInfoType['data'] = {
      companyName: '',
      userDTO: [],
      adminList: [],
      logoImagePath: '',
      id: { timestamp: 0, date: 0 },
      code: '',
    },
    action: clientInfoType,
  ) => {
    switch (action.type) {
      case SET_CLINET_INFO:
        state = action.data
        return state
      case RESET_CLIENT_INFO:
        state = {
          companyName: '',
          userDTO: [],
          adminList: [],
          logoImagePath: '',
          id: { timestamp: 0, date: 0 },
          code: '',
        }
        return state
      default:
        return state
    }
  }

  const getSubModules = (state = [], action: actions) => {
    switch (action.type) {
      case GET_SUB_MODULE[SUCCESS]:
        return action.payload
      case GET_SUB_MODULE[REQUEST]:
        return []
      case GET_SUB_MODULE[RESET]:
        return []
      case GET_SUB_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  const getClientFeatures = (state = [], action: actions) => {
    switch (action.type) {
      case GET_CLIENT_FEATURES[SUCCESS]:
        return action.payload
      case GET_CLIENT_FEATURES[REQUEST]:
        return []
      case GET_CLIENT_FEATURES[RESET]:
        return []
      case GET_CLIENT_FEATURES[RESET]:
        return []
      default:
        return state
    }
  }

  const featureIdInfo = (state = [], action: actions) => {
    switch (action.type) {
      case FEATURE_IDS[REQUEST]:
        if (action?.data) state = action?.data
        return state
      case FEATURE_IDS[RESET]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    getAllClients,
    createClients,
    editClient,
    deleteClient,
    assignModule,
    assignSubModule,
    updateClient,
    getClientModules,
    clientAssignModules,
    getAssignedModules,
    getClientInfo,
    getSubModules,
    getClientFeatures,
    featureIdInfo,
  })
}

export default entity

export const getClientManagementData = (state: RootState) => state.entities.clientManagement
