import { GetAccessToken } from '../auth/types'
import { IData } from '../components/Dashboard/Types'
import {
  DeleteModuleTypes,
  ModuleCreate,
  ModuleUpdate,
  NewModuleSave,
  ResponeModuleManagement,
  SubModuleCardData,
  SubModuleDelete,
} from '../components/Module Management/ModuleManagementTypes'
import {
  GetUserById,
  SavedUserDetailsType,
  UserBasicType,
  UserClientDeleteType,
  UserDTOCreateType,
  UserDTOUpdateType,
  UserDeleteType,
  UserModuleDeleteType,
  UserSubModuleDeleteType,
  UserTypes,
} from '../components/User_Management/UserManagementTypes'
import { KeyStringValueStringSaga } from '../sagas/Types'
import {
  OauthResponse,
  IAuthData,
  RefreshAccessToken,
  getAllClientsType,
  createClientsType,
  editDeleteClientRequestType,
  assignModuleRequestType,
  assignSubModuleRequestType,
  getClientModulesRequestType,
  modulesType,
  getSubModuleRequestType,
} from './Types'
import * as actions from './actiontypes'
import {
  ADD_NEW_USER,
  CREATE_MODULE,
  CREATE_SUB_MODULE,
  DELETE_MODULE,
  DELETE_SUB_MODULE,
  DELETE_USER,
  FORGOT_PASSWORD,
  GET_ALL_USER,
  GET_MODULE_BY_ID,
  GET_USER_BY_ID,
  LOGOUT,
  REMEMBER_MODULE_NAME,
  REMEMBER_USER_DETAILS,
  RESET_PASSWORD,
  RESEND_RESET_PASSWORD_LINK,
  SAVE_MODULE_DATA_FOR_USER,
  SAVE_SUB_MODULE,
  UPDATE_MODULE,
  UPDATE_SUB_MODULE,
  UPDATE_USER,
} from './actiontypes'

const {
  SUCCESS,
  REQUEST,
  FAILURE,
  RESET,
  GET_USER_DATA,
  REFRESH_ACCESS_TOKEN,
  LOGIN,
  GET_ACCESS_TOKEN,
  GET_ALL_CLIENTS,
  GET_ALL_MODULES,
  CREATE_CLIENTS,
  UPLOAD_IMAGE,
  EDIT_CLIENT,
  ASSIGN_MODULE,
  ASSIGN_SUB_MODULE,
  DELETE_CLIENT,
  UPDATE_CLIENT,
  GET_CLIENT_MODULES,
  FEATURE_IDS,
  action,
} = actions

// Bellow are the Action Objects
export const getUserData = {
  request: () => action(GET_USER_DATA[REQUEST]),
  success: (response: IData) => action(GET_USER_DATA[SUCCESS], { response }),
  failure: () => action(GET_USER_DATA[FAILURE]),
  reset: () => action(GET_USER_DATA[RESET], {}),
}
export const getUserAuthentication = {
  request: (data: IAuthData) => action(LOGIN[REQUEST], { data }),
  success: (response: OauthResponse) => action(LOGIN[SUCCESS], { response }),
  failure: () => action(LOGIN[FAILURE]),
  reset: () => action(LOGIN[RESET], {}),
}

export const getUserLogout = {
  request: () => action(LOGOUT[REQUEST]),
  success: () => action(LOGOUT[SUCCESS]),
  failure: () => action(LOGOUT[FAILURE]),
  reset: () => action(LOGOUT[RESET], {}),
}

export const getRefreshAccessToken = {
  request: (data: RefreshAccessToken) => {
    return action(REFRESH_ACCESS_TOKEN[REQUEST], { data })
  },
  success: (response: OauthResponse) => action(REFRESH_ACCESS_TOKEN[SUCCESS], { response }),
  failure: () => action(REFRESH_ACCESS_TOKEN[FAILURE]),
  reset: () => action(REFRESH_ACCESS_TOKEN[RESET], {}),
}

export const renewAccessToken = {
  request: (data: GetAccessToken) => action(GET_ACCESS_TOKEN[REQUEST], { data }),
  success: (response: GetAccessToken) => action(GET_ACCESS_TOKEN[SUCCESS], { response }),
  failure: () => action(GET_ACCESS_TOKEN[FAILURE]),
  reset: () => action(GET_ACCESS_TOKEN[RESET], {}),
}

export const getAllClients = {
  request: (data?: getAllClientsType) => action(GET_ALL_CLIENTS[REQUEST], { data }),
  success: (response: GetAccessToken) => action(GET_ALL_CLIENTS[SUCCESS], { response }),
  failure: () => action(GET_ALL_CLIENTS[FAILURE]),
  reset: () => action(GET_ALL_CLIENTS[RESET], {}),
}

export const getAllModules = {
  request: () => action(GET_ALL_MODULES[REQUEST]),
  success: (response: ResponeModuleManagement) => action(GET_ALL_MODULES[SUCCESS], { response }),
  failure: () => action(GET_ALL_MODULES[FAILURE]),
  reset: () => action(GET_ALL_MODULES[RESET], {}),
}

export const deleteModule = {
  request: (data: DeleteModuleTypes) => action(DELETE_MODULE[REQUEST], { data }),
  success: (response: ResponeModuleManagement) => action(DELETE_MODULE[SUCCESS], { response }),
  failure: () => action(DELETE_MODULE[FAILURE]),
  reset: () => action(DELETE_MODULE[RESET], {}),
}

export const createModule = {
  request: (data: ModuleCreate) => action(CREATE_MODULE[REQUEST], { data }),
  success: (response: ResponeModuleManagement) => action(CREATE_MODULE[SUCCESS], { response }),
  failure: () => action(CREATE_MODULE[FAILURE]),
  reset: () => action(CREATE_MODULE[RESET], {}),
}

export const updateModule = {
  request: (data: ModuleUpdate) => action(UPDATE_MODULE[REQUEST], { data }),
  success: (response: ResponeModuleManagement) => action(UPDATE_MODULE[SUCCESS], { response }),
  failure: () => action(UPDATE_MODULE[FAILURE]),
  reset: () => action(UPDATE_MODULE[RESET], {}),
}

export const getModuleById = {
  request: (data: DeleteModuleTypes) => action(GET_MODULE_BY_ID[REQUEST], { data }),
  success: (response: ResponeModuleManagement) => action(GET_MODULE_BY_ID[SUCCESS], { response }),
  failure: () => action(GET_MODULE_BY_ID[FAILURE]),
  reset: () => action(GET_MODULE_BY_ID[RESET], {}),
}

export const createSubModule = {
  request: (payload: NewModuleSave[]) => action(CREATE_SUB_MODULE[REQUEST], { payload }),
  success: (response: ResponeModuleManagement) => action(CREATE_SUB_MODULE[SUCCESS], { response }),
  failure: () => action(CREATE_SUB_MODULE[FAILURE]),
  reset: () => action(CREATE_SUB_MODULE[RESET], {}),
}

export const uploadImage = {
  request: (data: any) => action(UPLOAD_IMAGE[REQUEST], { data }),
  success: (response: any) => action(UPLOAD_IMAGE[SUCCESS], { response }),
  failure: () => action(UPLOAD_IMAGE[FAILURE]),
  reset: () => action(UPLOAD_IMAGE[RESET], {}),
}

export const saveModuleName = {
  request: (payload: ModuleUpdate | NewModuleSave) =>
    action(REMEMBER_MODULE_NAME[REQUEST], { payload }),
  reset: () => action(REMEMBER_MODULE_NAME[RESET], {}),
}

export const getAllUsers = {
  request: () => action(GET_ALL_USER[REQUEST]),
  success: (response: UserTypes) => action(GET_ALL_USER[SUCCESS], { response }),
  failure: () => action(GET_ALL_USER[FAILURE]),
  reset: () => action(GET_ALL_USER[RESET], {}),
}

export const updateUser = {
  request: (data: UserDTOUpdateType) => action(UPDATE_USER[REQUEST], { data }),
  success: (response: UserDTOUpdateType) => action(UPDATE_USER[SUCCESS], { response }),
  failure: () => action(UPDATE_USER[FAILURE]),
  reset: () => action(UPDATE_USER[RESET], {}),
}

export const deleteUser = {
  request: (data: UserDeleteType) => action(DELETE_USER[REQUEST], { data }),
  success: (response: UserDeleteType) => action(DELETE_USER[SUCCESS], { response }),
  failure: () => action(DELETE_USER[FAILURE]),
  reset: () => action(DELETE_USER[RESET], {}),
}

export const addUser = {
  request: (data: UserDTOCreateType) => action(ADD_NEW_USER[REQUEST], { data }),
  success: (response: UserDTOCreateType) => action(ADD_NEW_USER[SUCCESS], { response }),
  failure: () => action(ADD_NEW_USER[FAILURE]),
  reset: () => action(ADD_NEW_USER[RESET], {}),
}

export const getUserById = {
  request: (data: GetUserById) => action(GET_USER_BY_ID[REQUEST], { data }),
  success: (response: GetUserById) => action(GET_USER_BY_ID[SUCCESS], { response }),
  failure: () => action(GET_USER_BY_ID[FAILURE]),
  reset: () => action(GET_USER_BY_ID[RESET], {}),
}

export const saveUserDetails = {
  request: (payload: SavedUserDetailsType) => action(REMEMBER_USER_DETAILS[REQUEST], { payload }),
  reset: () => action(REMEMBER_USER_DETAILS[RESET], {}),
}

export const createClient = {
  request: (data: createClientsType) => action(CREATE_CLIENTS[REQUEST], { data }),
  success: (response: object) => action(CREATE_CLIENTS[SUCCESS], { response }),
  failure: () => action(CREATE_CLIENTS[FAILURE]),
  reset: () => action(CREATE_CLIENTS[RESET], {}),
}

export const editClient = {
  request: (data: editDeleteClientRequestType) => action(EDIT_CLIENT[REQUEST], { data }),
  success: (response: object) => action(EDIT_CLIENT[SUCCESS], { response }),
  failure: () => action(EDIT_CLIENT[FAILURE]),
  reset: () => action(EDIT_CLIENT[RESET], {}),
}

export const deleteClient = {
  request: (data: editDeleteClientRequestType) => action(DELETE_CLIENT[REQUEST], { data }),
  success: (response: object) => action(DELETE_CLIENT[SUCCESS], { response }),
  failure: () => action(DELETE_CLIENT[FAILURE]),
  reset: () => action(DELETE_CLIENT[RESET], {}),
}

export const assignModule = {
  request: (data: assignModuleRequestType) => action(ASSIGN_MODULE[REQUEST], { data }),
  success: (response: object) => action(ASSIGN_MODULE[SUCCESS], { response }),
  failure: () => action(ASSIGN_MODULE[FAILURE]),
  reset: () => action(ASSIGN_MODULE[RESET], {}),
}

export const assignSubModule = {
  request: (data: assignSubModuleRequestType) => action(ASSIGN_SUB_MODULE[REQUEST], { data }),
  success: (response: object) => action(ASSIGN_SUB_MODULE[SUCCESS], { response }),
  failure: () => action(ASSIGN_SUB_MODULE[FAILURE]),
  reset: () => action(ASSIGN_SUB_MODULE[RESET], {}),
}

export const updateClient = {
  request: (data: createClientsType) => action(UPDATE_CLIENT[REQUEST], { data }),
  success: (response: object) => action(UPDATE_CLIENT[SUCCESS], { response }),
  failure: () => action(UPDATE_CLIENT[FAILURE]),
  reset: () => action(UPDATE_CLIENT[RESET], {}),
}

export const deleteSubModule = {
  request: (data: SubModuleDelete) => action(DELETE_SUB_MODULE[REQUEST], { data }),
  success: (response: ResponeModuleManagement) => action(DELETE_SUB_MODULE[SUCCESS], { response }),
  failure: () => action(DELETE_SUB_MODULE[FAILURE]),
  reset: () => action(DELETE_SUB_MODULE[RESET], {}),
}

export const saveSubModuleDetails = {
  request: (payload: ModuleUpdate) => action(SAVE_SUB_MODULE[REQUEST], { payload }),
  reset: () => action(SAVE_SUB_MODULE[RESET], {}),
}

export const updateSubModule = {
  request: (data: SubModuleCardData) => action(UPDATE_SUB_MODULE[REQUEST], { data }),
  success: (response: ResponeModuleManagement) => action(UPDATE_SUB_MODULE[SUCCESS], { response }),
  failure: () => action(UPDATE_SUB_MODULE[FAILURE]),
  reset: () => action(UPDATE_SUB_MODULE[RESET], {}),
}

export const getClientModules = {
  request: (data: getClientModulesRequestType) => action(GET_CLIENT_MODULES[REQUEST], { data }),
  success: (response: object) => action(GET_CLIENT_MODULES[SUCCESS], { response }),
  failure: () => action(GET_CLIENT_MODULES[FAILURE]),
  reset: () => action(GET_CLIENT_MODULES[RESET], {}),
}

export const clientAssignModules = {
  addModules: (data: modulesType[]) => action(actions.ADD_ASSIGNED_MODULES, { data }),
  resetModule: () => action(actions.RESET_ASSIGNED_MODULES, {}),
}

export const getAssignedModules = {
  mergAssignedModule: (data: modulesType[]) => action(actions.MERG_SELECTED_MODULE, { data }),
  resetAssignedModule: () => action(actions.RESET_SELECTED_MODULE, {}),
  removeAssignedModule: (data: modulesType) => action(actions.REMOVE_ASSIGNED_MODULE, { data }),
}

export const clientInfo = {
  setClientInfo: (data: object) => action(actions.SET_CLINET_INFO, { data }),
  resetClientInfo: () => action(actions.RESET_CLIENT_INFO, {}),
}

export const saveModuleDataForUser = {
  request: (payload: ModuleUpdate) => action(SAVE_MODULE_DATA_FOR_USER[REQUEST], { payload }),
  reset: () => action(SAVE_MODULE_DATA_FOR_USER[RESET], {}),
}

export const clientDataFlag = {
  success: () => action(actions.CLIENT_DATA_FETCHED[SUCCESS], {}),
  reset: () => action(actions.CLIENT_DATA_FETCHED[RESET], {}),
}

export const getSubModule = {
  request: (data: getSubModuleRequestType) => action(actions.GET_SUB_MODULE[REQUEST], { data }),
  success: (response: object) => action(actions.GET_SUB_MODULE[SUCCESS], { response }),
  failure: () => action(actions.GET_SUB_MODULE[FAILURE]),
  reset: () => action(actions.GET_SUB_MODULE[RESET], {}),
}

export const deleteUserModule = {
  request: (data: UserModuleDeleteType) => action(actions.DELETE_USER_MODULE[REQUEST], { data }),
  success: (response: UserModuleDeleteType) =>
    action(actions.DELETE_USER_MODULE[SUCCESS], { response }),
  failure: () => action(actions.DELETE_USER_MODULE[FAILURE]),
  reset: () => action(actions.DELETE_USER_MODULE[RESET], {}),
}

export const deleteUserClient = {
  request: (data: UserClientDeleteType) => action(actions.DELETE_USER_CLIENT[REQUEST], { data }),
  success: (response: UserClientDeleteType) =>
    action(actions.DELETE_USER_CLIENT[SUCCESS], { response }),
  failure: () => action(actions.DELETE_USER_CLIENT[FAILURE]),
  reset: () => action(actions.DELETE_USER_CLIENT[RESET], {}),
}

export const deleteUserSubModule = {
  request: (data: UserSubModuleDeleteType) =>
    action(actions.DELETE_USER_SUB_MODULE[REQUEST], { data }),
  success: (response: UserSubModuleDeleteType) =>
    action(actions.DELETE_USER_SUB_MODULE[SUCCESS], { response }),
  failure: () => action(actions.DELETE_USER_SUB_MODULE[FAILURE]),
  reset: () => action(actions.DELETE_USER_SUB_MODULE[RESET], {}),
}

export const getClientKeys = {
  request: (data: object) => action(actions.GET_CLIENT_KEYS[REQUEST], { data }),
  success: (response: any) => action(actions.GET_CLIENT_KEYS[SUCCESS], { response }),
  failure: () => action(actions.GET_CLIENT_KEYS[FAILURE]),
  reset: () => action(actions.GET_CLIENT_KEYS[RESET], {}),
}

export const getForgotPassword = {
  request: (data: object) => action(FORGOT_PASSWORD[REQUEST], { data }),
  success: (response: any) => action(FORGOT_PASSWORD[SUCCESS], { response }),
  failure: () => action(FORGOT_PASSWORD[FAILURE]),
  reset: () => action(FORGOT_PASSWORD[RESET], {}),
}

export const getResetPassword = {
  request: (data: object) => action(RESET_PASSWORD[REQUEST], { data }),
  success: (response: any) => action(RESET_PASSWORD[SUCCESS], { response }),
  failure: () => action(RESET_PASSWORD[FAILURE]),
  reset: () => action(RESET_PASSWORD[RESET], {}),
}

export const getClientFeatures = {
  request: (data: { [key: string]: string }) =>
    action(actions.GET_CLIENT_FEATURES[REQUEST], { data }),
  success: (response: any) => action(actions.GET_CLIENT_FEATURES[SUCCESS], { response }),
  failure: () => action(actions.GET_CLIENT_FEATURES[FAILURE]),
  reset: () => action(actions.GET_CLIENT_FEATURES[RESET], {}),
}

export const featureIdInfo = {
  setFeatureIds: (data: string[]) => action(FEATURE_IDS[REQUEST], { data }),
  resetFeatureIds: () => action(FEATURE_IDS[RESET]),
}

export const resendPasswordReset = {
  request: (data : object) => action(RESEND_RESET_PASSWORD_LINK[REQUEST], {data}),
  success: (response: any) => action(RESEND_RESET_PASSWORD_LINK[SUCCESS], { response }),
  failure: () => action(RESEND_RESET_PASSWORD_LINK[FAILURE]),
  reset: () => action(RESEND_RESET_PASSWORD_LINK[RESET], {}),
}

export const actionTypes = {
  ...actions,
}
