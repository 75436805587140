import styled from '@emotion/styled'
import { Button, Stack, Typography } from '@mui/material'
import { Primary } from '../../utils'

// Styled Components
const MainContainer = styled(Stack)({
  boxSizing: 'border-box',
  height: '100%',
  width: '100%',
})
const LeftContainer = styled('img')({
  width: '50%',
  height: '100%',
})
const RightContainer = styled(Stack)({
  padding: '8% 10% 0% 10%',
  boxSizing: 'border-box',
  flexDirection: 'column',
  width: '50%',
  height: '100%',
  position: 'relative',
  justifyContent: 'flex-start',
  backgroundColor: '#FFF',
})
const Logo = styled('img')({
  width: '210px',
  height: '75px',
  marginBottom: '55px',
})

const EsgLogo = styled('img')({
  width: '55px',
  marginRight: '10px',
})

const Heading = styled(Typography)<{ fontsize?: string | number | null }>(({ fontsize }) => {
  return {
    marginBottom: '11px',
    fontWeight: 'bold',
    fontSize: fontsize ?? '33px',
  }
})

const Details = styled(Typography)({
  marginBottom: '11px',
  fontWeight: '450',
  fontSize: '15px',
  width: '80%',
  textAlign: 'center',
})

const RememberMeOuterDiv = styled(Stack)({
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '25px',
})

const LangDrop = styled(Stack)({
  position: 'absolute',
  top: '25px',
  right: '30px',
  height: 'auto',
})

const NavigateButton = styled(Button)({
  fontSize: '15px',
  width: '150px',
  marginTop: '40px',
  height: '45px',
})

const LoginButton = styled(Button)({
  backgroundColor: Primary,
  color: '#FFF',
  fontWeight: 'bold',
  fontSize: 'large',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: Primary,
    color: '#fff',
  },
})

export {
  MainContainer,
  RightContainer,
  LangDrop,
  Logo,
  LeftContainer,
  Heading,
  RememberMeOuterDiv,
  EsgLogo,
  Details,
  NavigateButton,
  LoginButton,
}
