import styled from '@emotion/styled'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { AvatarGroup, Box, Button, Card, Stack, Tab, Tabs } from '@mui/material'

//Here are the classes
const NewModuleHeader = {
  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  height: '30%',
  justifyContent: 'flex-start',
  gap: '20px',
  pt: '5px',
  mt: '10px',
}

const SubModuleContainer = {
  height: '60%',
  'flex-direction': 'column',
  ml: '20px',
}

const SubModuleHeader = {
  height: '20%',
  gap: '5%',
  justifyContent: 'center',
  mt: '10px',
  ml: '20px',
}

const ModuleNameContainer = {
  height: '100%',
  ml: '20px',
  width: '30%',
}

//Here are the styled components
const MainContainer = styled(Box)<{ height?: string }>((props) => ({
  margin: '20px 12px 10px 12px',
  width: '98%',
  height: props.height ? props.height : '83%',
  boxShadow: '0px 3px 6px #00000029',
  padding: '0px 40px 30px 40px',
  overflow: 'hidden',
  backgroundColor: '#fff',
  boxSizing: 'border-box',
}))

const DescriptionBox = styled(Box)({
  width: '100%',
  heigth: 'auto',
})

const ContentBox = styled(Box)({
  display: 'flex',
  width: '100%',
  gap: '20px',
  flexDirection: 'row',
  marginTop: '20px',
})

const Image = styled.img<{ src: string; alt?: string; size?: string }>`
  height: ${(props) => (props.size ? props.size : `40px`)};
  width: ${(props) => (props.size ? props.size : `40px`)};
  ${(props) => props.src && `src: ${props.src};`}
  ${(props) => props.alt && `alt: ${props.alt};`}
`

const ImageContainer = styled(Card)({
  padding: '6px',
  marginLeft: '10px',
  borderRadius: '5px',
  boxShadow:
    '0px 3px 6px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
})

const TypeToSearch = styled(Box)({
  backgroundColor: '#F3F3F3',
  width: '280px',
  minHeight: '30px',
  height: 'fit-content',
  padding: '5px 0px 5px 10px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const CustomTabs = styled(Tabs)({
  width: '100%',
})

const CustomTab = styled(Tab)({
  width: '25%',
  textTransform: 'none',
  fontSize: '13.5px',
  ' &.Mui-selected': {
    fontWeight: 'bold',
    color: 'black',
  },
})

const AllModuleContainer = styled(Stack)<{ height?: string; gap?: string }>(
  (props: { height?: string; gap?: string }) => ({
    width: '100%',
    height: props.height ? props.height : '73%',
    // flexDirection: 'row',
    gap: props.gap ? props.gap : '2%',
    // flexWrap: 'wrap',
    marginTop: '10px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflow: 'auto',
    rowGap: '10px',
    padding: '5px',
  }),
)

const StyledCard = styled(Card)<{
  image?: string
  isactive?: boolean
  height?: string
  cursorpointer?: boolean
}>`
  // background: linear-gradient(180deg, rgba(43, 3, 3, 0) 58%, rgba(0, 0, 0, 0.7371323529411764) 80%),
  //   url(${({ image }) => image});
  // background:${(props) =>
    props.isactive
      ? 'linear-gradient(180deg, rgba(43, 3, 3, 0) 58%, rgba(0, 0, 0, 0.7371323529411764) 80%)'
      : `linear-gradient(180deg, rgba(38,27,27,0.6615021008403361) 19%, rgba(46,29,29,0.9332107843137255) 92%)`};
  background: ${(props) =>
    props.isactive
      ? `linear-gradient(180deg, rgba(43, 3, 3, 0) 58%, rgba(0, 0, 0, 0.7371323529411764) 80%), url('${props.image}')`
      : `linear-gradient(180deg, rgba(15,15,15,0.7791491596638656) 24%, rgba(56,47,47,0.8519782913165266) 76%), url('${props.image}')`};
  background-size: cover;
  background-repeat: no-repeat;
  height: ${(props) => (props.height ? props.height : '120px')};
  width: 16.5%;
  border-radius: 15px;
  padding: 10px;
  min-width: 160px;
  // filter: ${(props) => (props.isactive ? 'none' : `grayscale(90%)`)};
  cursor: ${(props) => (props.cursorpointer ? 'pointer' : `default`)};
  & .MuiBox-root.dot-box {
    border-radius: 50%;
    background: red;
    height: 10px;
    width: 10px;
  }
`

const StyledAvatarGroup = styled(AvatarGroup)<{ maxreached?: boolean }>`
  & .MuiAvatar-root:not(:last-child) {
    margin-left: -1rem;
  }
  ${(props) =>
    !props.maxreached
      ? ''
      : `
    & .MuiAvatar-root:first-of-type {
      border: none;
      margin-left: 5px;
      background: none;
    }
  `}
  .MuiAvatarGroup-avatar {
    height: 30px;
    width: 30px;
    position: static;
    font-size: 17px;
  }
`

const WhiteMoreHorizIcon = styled(MoreHorizIcon)({
  color: '#fff',
  fontSize: '25px',
})

const StyledButton = styled(Button)<{ isActive?: boolean; bgcolor?: string }>`
  width: auto;
  height: 35px;
  border-radius: 10px;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : '#FFFFFF')};
  border-color: #a90076;
  color: ${(props) => (props.isActive || props.bgcolor ? '#FFFFFF' : '#A90076')};
  font-size: 14px;
  display: flex;
  line-height: 0.9;
  padding: 14px;
  &:hover,
  &:active {
    background-color: #a90076;
    box-shadow: none;
    color: #ffffff;
    border-color: #a90076;
  }
`

const SmallContainer = styled(Stack)({
  height: '200px',
  width: '70%',
  backgroundColor: '#FFFFFF',
  flexDirection: 'column',
  paddingLeft: '30px',
  paddingRight: '30px',
  margin: '20px 12px 10px 12px',
  boxShadow: '0px 3px 6px #00000029',
  padding: '0px 20px 30px 20px',
  overflow: 'hidden',
})
const StyledContainedButton = styled(Button)(() => ({
  backgroundColor: '#A90076',
  fontSize: '14px',
  color: '#FFFFFF',
  borderRadius: '8px',
  borderColor: '#86868633',
  // textTransform: 'none',
  padding: '5px 10px',
  '&:hover': {
    color: '#FFFFFF',
    backgroundColor: '#A90076',
  },
}))

export {
  AllModuleContainer,
  ContentBox,
  CustomTab,
  CustomTabs,
  DescriptionBox,
  Image,
  ImageContainer,
  MainContainer,
  ModuleNameContainer,
  NewModuleHeader,
  SmallContainer,
  StyledAvatarGroup,
  StyledButton,
  StyledCard,
  StyledContainedButton,
  SubModuleContainer,
  SubModuleHeader,
  TypeToSearch,
  WhiteMoreHorizIcon,
}
