import styled from '@emotion/styled'
import { Button, InputLabel, List, Paper, Select, Stack, Table, TextField } from '@mui/material'

//Below are classes

const Heading = {
  fontSize: '18px',
  fontWeight: 'bold',
}

const SubHeading = () => {
  return { color: '#999999', mt: '-7px' }
}

const AddUserTableContainer = {
  padding: '10px 20px',
  backgroundColor: '#fbf9ff',
}

const AddClientDesc = {
  backgroundColor: '#fff',
  paddingLeft: '20px',
  height: '60px',
  boxShadow: '0px 3px 3px #00000029',
  borderRadius: '10px',
  '& h6': {
    fontWeight: '200',
    fontSize: '14px',
  },
}

const avtarStyle = { height: '30px', width: '30px', marginRight: '10px', marginLeft: '5px' }

//Below are styled component
const ModalContainer = styled(Paper)({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '20px 30px',
  width: '500px',
  borderRadius: '10px',
})

const InputContainer = styled(Stack)({
  flexDirection: 'column',
  alignItems: 'flex-start',
})
const StyledLable = styled(InputLabel)({
  fontSize: '13px',
  fontWeight: '450',
  color: 'black',
  marginLeft: '20px',
})
const StyledSelectInput = styled(Select)({
  height: '40px',
  width: '300px',
  borderRadius: '10px',
  color: '#000',
})
const ModalButtonConatiner = styled(Stack)({
  width: '100%',
  flexDirection: 'row',
  gap: '20px',
  justifyContent: 'flex-end',
  marginTop: '50px',
})

const PopupButton = styled(Button)<{ bgcolor?: string }>`
  height: 35px;
  border-radius: 10px;
  font-size: 14px;
  min-width: 110px;
  background-color: ${(props) => props.bgcolor || 'defaultBgColor'};
  color: ${(props) => (props.bgcolor ? 'white' : 'defaultTextColor')};
`

const StyledTextField = styled(TextField)({
  height: '40px',
  minWidth: '200px',
  margin: '0px 0px',
  borderRadius: '10px',
})

const AddUserContainer = styled(Stack)({
  width: '100%',
  alignItems: 'flex-start',
  gap: '3%',
  marginTop: '10px',
})

const AddUserInputContainer = styled(Stack)({
  width: '49%',
  flexDirection: 'column',
  gap: '10px',
})

const AddUserSmallInput = styled(Stack)({
  padding: '10px 20px',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: '#fbf9ff',
  maxHeight: '200px',
})

const SmallTable = styled(Table)({
  maxWidth: '300px',
  borderRadius: '6px',
  '& thead': {
    backgroundColor: '#A90076',
    borderRadius: '6px',
  },
  '& tbody': {
    maxHeight: '100px',
  },
  '& thead > th': {
    color: '#fff',
    fontWeight: '500',
    padding: '8px',
  },
})

const StyledList = styled(List)({
  padding: '0px',
  borderRadius: '10px',
  '& li': {
    padding: '0px',
  },
  '& .MuiPaper-root-MuiMenu-paper-MuiPopover-paper': {
    borderRadius: '10px',
  },
})

const Line = styled.div`
  position: absolute;
  width: auto;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  top: 52%;
  padding: 0px 20px;
  min-width: 380px;
`

const AssignedModuleContainer = styled(Stack)({
  padding: '10px 10px 20px 10px',
  backgroundColor: '#F8F9FF',
  height: '95%',
  borderRadius: '8px',
  flexDirection: 'column',
  width: '48%',
  minWidth: '450px',
  boxSizing: 'border-box',
})

const ClientCard = styled(Stack)({
  height: '110px',
  width: '110px',
  backgroundColor: '#fff',
  boxShadow: '0px 1px 1px #00000029',
  borderRadius: '5px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 10px',
  boxSizing: 'border-box',
  gap: '2px',
  overflow: 'hidden',
})

const StyledContainedButton = styled(Button)(() => ({
  backgroundColor: '#A90076',
  fontSize: '14px',
  color: '#FFFFFF',
  borderRadius: '8px',
  borderColor: '#86868633',
  boxShadow: 'none',
  // textTransform: 'none',
  padding: '5px 10px',
  '&:hover': {
    color: '#FFFFFF',
    boxShadow: 'none',

    backgroundColor: '#A90076',
  },
}))

export {
  StyledContainedButton,
  ModalContainer,
  InputContainer,
  StyledLable,
  StyledSelectInput,
  ModalButtonConatiner,
  PopupButton,
  StyledTextField,
  Heading,
  SubHeading,
  AddUserContainer,
  AddUserInputContainer,
  AddUserSmallInput,
  AddUserTableContainer,
  AddClientDesc,
  SmallTable,
  StyledList,
  Line,
  AssignedModuleContainer,
  ClientCard,
  avtarStyle,
}
