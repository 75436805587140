import { Box, Stack, Typography } from '@mui/material'
import {
  MainContainer,
  DescriptionBox,
  ContentBox,
} from '../Module Management/ModuleManagementComponents'
import { StyledSearchIcon, StyledSearchInputBase } from '../Dashboard/DashboardStyles'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import {
  FullWidthStack,
  FlexendStack,
  ColumnStack,
  StyledSortButton,
  StyledHTMLImg,
  TypeToSearch,
} from '../Common/CommonStyles'
import StyledTable from '../Common/StyledTable'
import { Heading, StyledContainedButton, SubHeading } from './UserManagementStyles'
import { connect } from 'react-redux'
import { RootState } from '../../configureStore'
import { Dispatch } from 'redux'
import { deleteUser, getAllUsers, getUserById, saveUserDetails } from '../../actions'
import { userManagementEntity } from '../../reducers'
import NoData from '../Common/NoData'
import {
  AllUserResponseTypes,
  TableDataTypesForUser,
  UserBasicType,
  UserDTO,
  UserDeleteType,
  UserResponseType,
  UserTypes,
} from './UserManagementTypes'
import swapImg from '../../assets/images/swap_vert_black_24dp.svg'
import DeletePopup from '../Common/DeletePopup'

//This value rwuired to draw table head from all data
const tableHeadForUserManegement = ['name', 'designation', 'email', 'clientCount']
/* eslint-disable */

interface UserManagementProps {
  getAllUsers: () => void
  allUsers: AllUserResponseTypes
  deletedUser: UserDeleteType
  resetDeleted: () => void
  deleteUser: (data: UserDeleteType) => void
  resetUserDetails: () => void
  resetSearchedUser: () => void
}

const UserManagement = (props: UserManagementProps) => {
  const { getAllUsers, allUsers, deletedUser, deleteUser, resetUserDetails, resetSearchedUser } =
    props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [allUserData, setAllUserData] = useState<UserResponseType[] | []>([])
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)
  const [selectedDataForDelete, setSelectedDataForDelete] = useState<TableDataTypesForUser | null>(
    null,
  )
  const [tableData, setTableData] = useState<TableDataTypesForUser[] | []>([])
  const [searchedData, setSearchedData] = useState<TableDataTypesForUser[]>([])
  const [searchedValue, setSearchedValue] = useState('')

  //This useEffect will get all Users in initial load as well when we delete one module
  useEffect(() => {
    getAllUsers()
    resetUserDetails()
    resetSearchedUser()
    localStorage.removeItem('searchedUser')
  }, [deletedUser])

  //This will set all user data to state while refresh
  useEffect(() => {
    if (allUsers?.content && allUsers?.content?.length > 0) setAllUserData(allUsers.content)
    const tableData: TableDataTypesForUser[] = []
    allUsers?.content?.map((singleUserData: UserResponseType) => {
      if (singleUserData.userDTO.status) {
        tableData.push({
          userData: singleUserData,
          name: singleUserData.userDTO.firstName,
          designation: singleUserData.userDTO.designation,
          email: singleUserData.userDTO.email,
          clientCount: singleUserData?.clientDTO?.length,
        })
      }
    })
    setTableData(tableData)
  }, [allUsers.content])

  //This functions called while click on add module button and it navigates to add user component
  const handleAddUserClick = () => {
    resetUserDetails()
    resetSearchedUser()
    navigate('adduser')
  }

  //This function handle functionalities related to edit user from table
  const handleEditUser = (data: { userData: { userDTO: UserDTO } }) => {
    resetUserDetails()
    resetSearchedUser()
    localStorage.setItem('searchedUser', data.userData.userDTO.id?.toString())
    navigate('edituser')
  }

  //This function will delete user from table delete click
  const handleDeleteUser = (data: TableDataTypesForUser) => {
    setSelectedDataForDelete(data)
    setDeleteConfirmation(true)
  }

  //This function will delete user and responsible for delete api call
  const confirmDelete = (value: string) => {
    if (value === 'cancel') {
      setSelectedDataForDelete(null)
      setDeleteConfirmation(false)
    } else if (value === 'delete' && selectedDataForDelete) {
      setDeleteConfirmation(false)
      deleteUser({
        id: selectedDataForDelete?.userData?.userDTO?.id,
        clientCode: selectedDataForDelete?.userData?.userDTO?.clientId,
      })
    }
  }

  //This function will sort table data by user name
  const sortTableData = () => {
    if (tableData?.length > 2) {
      tableData.sort((userDataA: TableDataTypesForUser, userDataB: TableDataTypesForUser) => {
        const nameA = userDataA.name?.toLowerCase() ?? ''
        const nameB = userDataB.name?.toLowerCase() ?? ''
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      })
      setTableData([...tableData])
    }
  }

  const handleSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedValue(e.target.value)
    if (e.target.value.trim().length > 0)
      setSearchedData(
        tableData?.filter((data: TableDataTypesForUser) => {
          return data?.name?.trim()?.toLowerCase().includes(e.target.value.trim().toLowerCase())
        }),
      )
  }

  return (
    <MainContainer>
      <ColumnStack>
        <DescriptionBox>
          <ContentBox>
            <Stack width={'65%'}>
              <Box>
                <Typography sx={Heading}>{t('users_management')}</Typography>
                <Typography sx={SubHeading} variant='caption'>
                  {t('user_manage_message')}
                </Typography>
              </Box>
            </Stack>
          </ContentBox>
          <ContentBox>
            <Stack direction='row' width={'55%'} spacing={2.5}>
              <TypeToSearch>
                <StyledSearchIcon />
                <StyledSearchInputBase
                  placeholder={`${t('type_to_search')}`}
                  inputProps={{ 'aria-label': 'Type to search' }}
                  onChange={handleSearchField}
                />
              </TypeToSearch>
              <StyledSortButton variant='outlined' onClick={sortTableData}>
                <StyledHTMLImg src={swapImg} alt='swapImg' />
                {t('sort_by_name')}
              </StyledSortButton>
            </Stack>
            <FlexendStack width={'45%'} gap={'20px'}>
              <StyledContainedButton
                variant='contained'
                startIcon={<AddIcon />}
                onClick={handleAddUserClick}
              >
                {t('add_user_button')}
              </StyledContainedButton>
            </FlexendStack>
          </ContentBox>
        </DescriptionBox>
        <FullWidthStack direction={'column'}>
          <StyledTable
            actions={['edit', 'delete']}
            tableData={searchedValue.trim().length > 0 ? searchedData : tableData}
            handleDelete={handleDeleteUser}
            handleEdit={handleEditUser}
            tableHead={tableHeadForUserManegement}
          />
          {(allUserData?.length === 0 || !allUserData) && (
            <FullWidthStack height={'300px'}>
              <NoData />
            </FullWidthStack>
          )}
        </FullWidthStack>
      </ColumnStack>
      <DeletePopup triggerAction={confirmDelete} open={deleteConfirmation} />
    </MainContainer>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    allUsers: userManagementEntity.getUsers(state).getAllUsers,
    deletedUser: userManagementEntity.getUsers(state).deleteUser,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllUsers: () => dispatch(getAllUsers.request()),
    resetDeleted: () => dispatch(deleteUser.reset()),
    deleteUser: (data: UserDeleteType) => dispatch(deleteUser.request(data)),
    resetUserDetails: () => dispatch(saveUserDetails.reset()),
    resetSearchedUser: () => dispatch(getUserById.reset()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
