import styled from '@emotion/styled'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import AddToPhotosTwoToneIcon from '@mui/icons-material/AddToPhotosTwoTone'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import SearchIcon from '@mui/icons-material/Search'
import {
  Accordion,
  AccordionDetails,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  ListItemIcon,
  Pagination,
  Radio,
  Select,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Primary } from '../../utils'
import { CustomSelectProps } from './Types'

//Below are the classes
const CustomSelect = styled(Select)<CustomSelectProps>(({ height = '35px' }) => ({
  padding: '0',
  height: height,
}))

const BoldIcon = { color: 'black', height: '20px' }

const RedIcon = { color: 'red', height: '20px' }

const ListButton = { height: '25px', marginLeft: '-4px' }

const LogoProps = {
  height: 'auto',
  width: '80%',
  maxHeight: '90px',
  maxWidth: '90px',
}

const AdminPaperSx = {
  padding: 2,
  borderRadius: '6px',
  border: '0.5px solid #A90076',
  boxShadow: 'none',
}
const TableContainerProp = (styles?: object) => {
  return {
    maxHeight: '315px',
    overScroll: 'auto',
    boxShadow: 'none',
    borderRadius: '10px',
    marginTop: '15px !important',
    ...styles,
  }
}
const TableProp = {
  minWidth: 500,
  width: '100%',
}

const TopBarUserNameStyle = {
  fontSize: '18px',
  color: 'gray',
}

const DialogTitleSx = { margin: '15px 20px 0px 20px' }

const DialogContentSx = { margin: '0px 20px 0px 20px', justifyContent: 'center', display: 'flex' }

const DialogActionSx = { justifyContent: 'center', margin: '0px 20px 15px 20px', gap: '20%' }

const loaderProps = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  height: '50px',
  width: '50px',
}

const CursorPointer = { cursor: 'pointer' }

const alignItemFlexStart = { alignItems: 'flex-start' }

//Belows are styled component
const TypeToSearch = styled(Card)<{ width?: string }>(({ width }) => ({
  backgroundColor: '#F3F3F3',
  width: width,
  height: 'fit-content',
  padding: '5px 0px 5px 10px',
  borderRadius: '8px',
  display: 'flex',
  boxShadow: 'none',
  alignItems: 'center',
}))

const StyledSearchIcon = styled(SearchIcon)({
  verticalAlign: 'middle',
  color: '#797979',
})

const StyledSearchInputBase = styled(InputBase)(() => ({
  marginLeft: 4,
  flex: 1,
  '& input': { fontSize: '14px', height: '100%', overflow: 'hidden', textOverflow: 'ellipsis' },
}))

const StyledSortButton = styled(Button)(() => ({
  backgroundColor: '#F6F7F7',
  fontSize: '14px',
  color: '#000000',
  borderRadius: '8px',
  borderColor: '#86868633',
  height: '39px',
  padding: '5px 10px',
  lineHeight: 1,
  '&:hover': {
    color: '#000000',
    borderColor: '#86868633',
    backgroundColor: '#F6F7F7',
  },
}))

const StyledContainedButton = styled(Button)(() => ({
  backgroundColor: Primary,
  fontSize: '14px',
  color: '#FFFFFF',
  borderRadius: '8px',
  borderColor: '#86868633',
  // textTransform: 'none',
  padding: '5px 10px',
  '&:hover': {
    color: '#FFFFFF',
    backgroundColor: Primary,
  },
}))

const StyledAddRoundedIcon = styled(AddRoundedIcon)(() => ({
  color: '#FFFFFF',
}))

const StyledSelect = styled(Select)({
  height: '35px',
  width: '80px',
  margin: '0px 20px 0px 10px',
})

const StyledPagination = styled(Pagination)({
  paddingRight: '1.5%',
  '& .Mui-selected': {
    color: '#fff',
    backgroundColor: '#A90076 !important',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: Primary,
      color: '#fff',
    },
    '&:active': {
      backgroundColor: Primary,
      color: '#fff',
    },
  },
  '& .MuiPaginationItem-icon': {
    backgroundColor: '#ffff',
    borderRadius: '50%',
  },
  '.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root': {
    fontWeight: '1000',
    fontSize: '13px !important',
  },
})

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '30px',
})

const PopperContainer = styled(Card)({
  borderRadius: '10px',
  width: '100px',
  '& .MuiTypography-root': {
    fontSize: '0.8rem',
  },
})

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'black',
  fontWeight: '450',
})

const BreadCrumbContainer = styled(Stack)({
  width: '97%',
  minHeight: '60px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  flexDirection: 'row',
  padding: '0px 15px',
})

const StyledBreadCrumbs = styled(Breadcrumbs)({
  marginTop: '10px',
  '& li:not(:last-child)': {
    opacity: 0.9,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& img': {
      opacity: 0.5,
    },
  },
  '& .MuiBreadcrumbs-separator': {
    margin: '0px 10px',
    fontSize: '28px',
    fontWeight: 'bold',
  },
})

const SmallIconButton = styled(Button)<{ iconbuttoncolor?: string }>`
  height: 35px;
  width: 35px;
  min-width: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.iconbuttoncolor ? props.iconbuttoncolor : '#FFFFFF')};
  :hover {
    background-color: ${Primary};
  }
  color: ${(props) => (props.iconbuttoncolor ? '#FFFFFF' : Primary)};
  border-color: #a90076 !important;
  '& svg' {
    font-size: 30px;
  }
`
const MandatoryMark = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: #ff0000;
`

const StyledTextField = styled(TextField)({
  marginTop: '3px',
  marginBottom: '8px',
  width: '90%',
  backgroundColor: '#FFF',
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
  },
})

const StyledFormLable = styled(FormControlLabel)({
  '& .MuiTypography-root': {
    fontSize: '0.8rem',
  },
  marginRight: '30px',
})

const StyledRadioButton = styled(Radio)({
  '&.Mui-checked': {
    color: Primary,
  },
})

const StyledLable = styled(InputLabel)({
  fontSize: '13px',
  fontWeight: '450',
  color: 'black',
})

const UploadButton = styled(InputLabel)({
  width: '60%',
  minWidth: '80px',
  height: '30px',
  color: '#fff',
  borderRadius: '10px',
  backgroundColor: Primary,
  fontSize: '13px',
  padding: '10px 10px 0px 10px',
  textAlign: 'center',
  cursor: 'pointer',
})

const ReplaceButton = styled(UploadButton)({
  width: '80px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 10px',
  fontSize: '15px',
  fontWeight: '500',
  gap: '2%',
})

const DropZoneContainer = styled(Stack)<{ height?: string }>`
  border: 2px dashed gray;
  border-radius: 10px;
  padding: 15px;
  textalign: center;
  fontsize: 1.2rem;
  height: ${(props) => (props.height ? props.height : '75px')};
  flex-direction: ${(props) => (props.height ? 'column' : 'row')};
  width: 100%;
  gap: 10px;
  justifycontent: space-between;
  overflow: hidden;
  max-width: 340px;
`

const StyledSelectInput = styled(Select)({
  height: '40px',
  width: '90%',
  borderRadius: '10px',
})

const AddSubModuleCard = styled(Stack)({
  height: '130px',
  width: '18.5%',
  minWidth: '180px',
  borderRadius: '10px',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
  flexDirection: 'column',
  justifyContent: 'center',
  textDecorationColor: 'red',
  cursor: 'pointer',
  '& .MuiTypography-root': {
    color: Primary,
    fontWeight: '500',
  },
  '& .MuiSvgIcon-root': {
    color: Primary,
    fontWeight: '500',
  },
})
const StyledHTMLImg = styled('img')<{
  width?: string | number
  height?: string | number
  display?: string
  margin?: string
}>(({ width, height, display, margin }) => {
  return {
    width: width,
    height: height,
    display: display,
    margin: margin,
  }
})

const CoustomCheckbox = styled(Checkbox)(() => ({
  padding: 0,
}))

//Stack that can be used where we need stack width 100%
const FullWidthStack = styled(Stack)({
  width: '100%',
})

const FullHeightStack = styled(Stack)({
  height: '100%',
})

const FullSizeStack = styled(Stack)({
  width: '100%',
  height: '100%',
})
const FullSizeCenterStack = styled(Stack)({
  width: '100%',
  height: '100%',
  justifyContent: 'center',
})

const ColumnStack = styled(Stack)({
  flexDirection: 'column',
})

const DropZoneLogo = styled(ColumnStack)({
  width: '45%',
  textAlign: 'center',
})

const FlexendStack = styled(Stack)({
  justifyContent: 'flex-end',
})
const CenterStack = styled(Stack)({
  justifyContent: 'center',
})
const SpaceBetweenStack = styled(Stack)({
  justifyContent: 'space-between',
})

const StyledTableHead = styled(TableHead)({
  position: 'sticky',
  top: 0,
  background: Primary,
  height: '25px',
  padding: '8px',
  borderRadius: '10px',
  zIndex: 2,
  '& .MuiTableCell-root': {
    color: '#fff',
    height: '30px',
  },
  '& .MuiTableRow-root': {
    borderBottom: 'none',
  },
})

const StyledTableRow = styled(TableRow)({
  height: '50px',
  padding: '8px',
  borderRadius: '10px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  '& .MuiTableCell-root': {
    borderBottom: 'none',
  },
  '& .MuiTableCell-root:first-of-type': {
    paddingLeft: '20px',
    borderRadius: '10px 0px 0px 10px',
  },
  '& .MuiTableCell-root:last-of-type': {
    borderRadius: '0px 10px 10px 0px',
  },
})

const StyledTableCell = styled(TableCell)({
  height: '20px',
  padding: '8px 5px',
  justifyContent: 'center',
  lineHeight: '1.5',
  letterSpacing: '0',
  fontSize: '14px',
  '& .MuiStack-root ': {
    justifyContent: 'center',
  },
})
const StyledTableMessage = styled(Typography)({
  color: '#999999',
})

const StyledAccordionDetails = styled(AccordionDetails)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
})
const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
})

const StyledImageCell = styled(Stack)({
  width: '100%',
  gap: '10px',
})

const StyledSubModuleIconButton = styled(IconButton)({
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: 'white',
  },
})

const StyledCrossIconButton = styled(IconButton)({
  // backgroundColor: 'white',
  '&:hover': {
    // backgroundColor: 'white',
  },
})

const StyledCrossIcon = styled(CloseRoundedIcon)({
  color: 'white',
})

const StyledSubModuleIcon = styled(AddToPhotosTwoToneIcon)({
  color: Primary,
})

const DeleteCancelPopupButton = styled(Button)({
  color: '#B80078',
  borderRadius: '8px',
  fontSize: '14px',
  height: '35px',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: 'white',
    color: '#B80078',
  },
  width: '100px',
  marginRight: '10px',
})

const DeletePopupButton = styled(Button)({
  borderRadius: '8px',
  fontSize: '14px',
  height: '35px',
  width: '100px',
  marginLeft: '10px',
})

const RightContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1;
`
const adminStackSX = {
  '&.MuiStack-root': {
    justifyContent: 'flex-start !important',
    width: '270px',
  },
}

const companyStackSX = { '&.MuiTableCell-root.MuiStack-root': { justifyContent: 'none' } }

const SubModuleBoxSx = { p: 0.5, border: '1.5px solid #A90076', borderRadius: '15px' }

const clientLogoStackSx = { justifyContent: 'flex-end !important', width: '45% !important' }

const clientNameStackSx = { justifyContent: 'flex-start !important', width: '55% !important' }

const cursor = { cursor: 'pointer' }

export {
  AddSubModuleCard,
  AdminPaperSx,
  BoldIcon,
  BreadCrumbContainer,
  CenterStack,
  ColumnStack,
  CoustomCheckbox,
  CursorPointer,
  CustomSelect,
  DeleteCancelPopupButton,
  DeletePopupButton,
  DialogActionSx,
  DialogContentSx,
  DialogTitleSx,
  DropZoneContainer,
  DropZoneLogo,
  FlexendStack,
  FullHeightStack,
  FullSizeCenterStack,
  FullSizeStack,
  FullWidthStack,
  ListButton,
  LogoProps,
  MandatoryMark,
  PopperContainer,
  RedIcon,
  ReplaceButton,
  RightContainer,
  SmallIconButton,
  SpaceBetweenStack,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAddRoundedIcon,
  StyledBreadCrumbs,
  StyledContainedButton,
  StyledCrossIcon,
  StyledCrossIconButton,
  StyledFormLable,
  StyledHTMLImg,
  StyledImageCell,
  StyledLable,
  StyledLink,
  StyledListItemIcon,
  StyledPagination,
  StyledRadioButton,
  StyledSearchIcon,
  StyledSearchInputBase,
  StyledSelect,
  StyledSelectInput,
  StyledSortButton,
  StyledSubModuleIcon,
  StyledSubModuleIconButton,
  StyledTableCell,
  StyledTableHead,
  StyledTableMessage,
  StyledTableRow,
  StyledTextField,
  SubModuleBoxSx,
  TableContainerProp,
  TableProp,
  TopBarUserNameStyle,
  TypeToSearch,
  UploadButton,
  adminStackSX,
  alignItemFlexStart,
  clientLogoStackSx,
  clientNameStackSx,
  companyStackSX,
  cursor,
  loaderProps,
}
