import CreateIcon from '@mui/icons-material/Create'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Avatar, Paper, Stack, Table, TableBody, TableContainer, Typography } from '@mui/material'
import Fade from '@mui/material/Fade'
import Popper from '@mui/material/Popper'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BrokenImageHandler from '../Clients/BrokenImageHandler'
import {
  AdminPaperSx,
  FullWidthStack,
  RedIcon,
  StyledContainedButton,
  StyledImageCell,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
  TableContainerProp,
  TableProp,
  adminStackSX,
  clientLogoStackSx,
  clientNameStackSx,
  companyStackSX,
  cursor,
} from './CommonStyles'

interface StyledTableProps {
  actions?: string[]
  handleDelete?: (data: any) => void
  handleEdit?: (data: any) => void
  handleResetPass?: (data: any) => void
  tableHead?: string[]
  tableData: any
  styles?: object
}

const stringAvatar = (firstName: string) => {
  return {
    sx: {
      bgcolor: 'white',
      color: '#A90076',
      boxShadow: '0px 3px 6px #00000029',
    },
    children:
      firstName?.split(' ').length > 1
        ? `${firstName?.split(' ')?.[0]?.charAt(0)}${firstName?.split(' ')?.[1]?.charAt(0)}`
        : firstName?.charAt(0),
  }
}

const restAdmins = () => {
  return {
    sx: {
      bgcolor: 'white',
      color: '#999999',
      cursor: 'pointer',
    },
  }
}

const StyledTable = (Props: StyledTableProps) => {
  const { tableData, actions, handleDelete, handleEdit, handleResetPass, tableHead, styles } = Props
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [admin, setAdmin] = useState<{ firstName: string; email: string }>({
    firstName: '',
    email: '',
  })

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    newAdmin: { firstName: string; email: string },
  ) => {
    setAnchorEl(event.currentTarget)
    setOpen((previousOpen) => !previousOpen)
    setAdmin({ ...admin, firstName: newAdmin.firstName, email: newAdmin.email })
  }
  const { t } = useTranslation()
  const tableHeadData: string[] =
    tableHead && tableHead.length > 0
      ? tableHead
      : tableData.length > 0
      ? Object.keys(tableData[0])
      : []
  return (
    <TableContainer component={Paper} sx={TableContainerProp(styles ? styles : {})}>
      <Popper open={open} anchorEl={anchorEl} placement='bottom-start' transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={AdminPaperSx}>
              <Typography variant='subtitle2'>{admin.firstName}</Typography>
              <Typography variant='caption'>{admin.email}</Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Table sx={TableProp} aria-label='simple table'>
        <StyledTableHead>
          <StyledTableRow>
            {tableHeadData?.length > 0 &&
              tableHeadData?.map((head: string, i: number) => {
                if (head === 'admins') {
                  return (
                    <StyledTableCell align='center' key={i}>
                      <Stack>
                        <Stack direction='row' spacing={1} sx={adminStackSX}>
                          {t(head)}
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                  )
                }
                return (
                  <StyledTableCell align='center' key={i}>
                    {t(head)}
                  </StyledTableCell>
                )
              })}
            {actions && actions.length > 0 && (
              <StyledTableCell align='center'>{t('action')}</StyledTableCell>
            )}
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {tableData?.map((data: any, Mapindex: number) => {
            return (
              <StyledTableRow key={Mapindex}>
                {tableHeadData?.length > 0 &&
                  tableHeadData.map((head: string, index: number) => {
                    if (head === 'company_name') {
                      return (
                        <StyledTableCell key={`${Mapindex}` + `${index}`}>
                          <Stack sx={companyStackSX}>
                            <FullWidthStack sx={clientLogoStackSx}>
                              <BrokenImageHandler data={data} />
                            </FullWidthStack>
                            {typeof data[head] === 'string' || typeof data[head] === 'number' ? (
                              <FullWidthStack sx={clientNameStackSx}>
                                <Typography>{data[head]}</Typography>
                              </FullWidthStack>
                            ) : Array.isArray(data[head]) ? (
                              <Typography>{data[head].length}</Typography>
                            ) : typeof data[head] === 'object' ? (
                              <StyledImageCell>
                                {data[head]?.logo && <img src={data[head]?.logo} height={'30px'} />}
                                <Typography>
                                  {data[head]?.companyName && data[head].companyName}
                                </Typography>
                              </StyledImageCell>
                            ) : null}
                          </Stack>
                        </StyledTableCell>
                      )
                    }
                    if (head === 'admins') {
                      return (
                        <StyledTableCell align='center' key={`${Mapindex}` + `${index}`}>
                          <Stack>
                            <Stack direction='row' spacing={1} sx={adminStackSX}>
                              {data?.admins?.length > 0 &&
                                data?.admins?.length !== 0 &&
                                data?.admins
                                  ?.slice(0, 5)
                                  .map((admin: { firstName: string; email: string }, i: number) => (
                                    <Avatar
                                      {...stringAvatar(admin?.firstName)}
                                      onMouseOver={(event: React.MouseEvent<HTMLElement>) => {
                                        handleClick(event, admin)
                                      }}
                                      onMouseLeave={(event: React.MouseEvent<HTMLElement>) => {
                                        handleClick(event, admin)
                                      }}
                                      key={`${Mapindex}` + `${index}` + `${i}`}
                                    />
                                  ))}
                              {data?.admins?.length > 0 && data?.admins?.length > 5 && (
                                <Typography
                                  {...restAdmins()}
                                  onClick={() => handleEdit && handleEdit(data)}
                                >{`+${data.admins.length - 5}`}</Typography>
                              )}
                            </Stack>
                          </Stack>
                        </StyledTableCell>
                      )
                    }
                    return (
                      <StyledTableCell align='center' key={head}>
                        {typeof data[head] === 'string' || typeof data[head] === 'number' ? (
                          <Typography>{data[head]}</Typography>
                        ) : Array.isArray(data[head]) ? (
                          <Stack>
                            <Typography>{data[head].length}</Typography>
                          </Stack>
                        ) : typeof data[head] === 'object' ? (
                          <StyledImageCell>
                            {data[head]?.logo && <img src={data[head]?.logo} height={'30px'} />}
                            {data[head]?.companyName && data[head].companyName}
                          </StyledImageCell>
                        ) : null}
                      </StyledTableCell>
                    )
                  })}
                {actions && actions.length > 0 && (
                  <StyledTableCell align='center'>
                    <Stack gap={'20px'}>
                      {actions.includes('edit') && (
                        <CreateIcon onClick={() => handleEdit && handleEdit(data)} sx={cursor} />
                      )}
                      {actions.includes('delete') && (
                        <DeleteOutlineIcon
                          sx={[RedIcon, cursor]}
                          onClick={() => handleDelete && handleDelete(data)}
                        />
                      )}
                      {actions.includes('reset-link') && (
                        <StyledContainedButton onClick={() => handleResetPass && handleResetPass(data?.email)}>
                          {t('reset_pass_link')}
                        </StyledContainedButton>
                      )}
                    </Stack>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StyledTable
