import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RESET } from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, GET_USER_DATA } = actionTypes

const entity = () => {
  const getUserData = (state = [], action: actions) => {
    switch (action.type) {
      case GET_USER_DATA[SUCCESS]:
        return action.payload
      case GET_USER_DATA[REQUEST]:
        return []
      case GET_USER_DATA[RESET]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    getUserData,
  })
}

export default entity

export const getDashboard = (state: RootState) => state.entities.dashboard
