import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { FORGOT_PASSWORD, GET_CLIENT_KEYS, RESET, RESET_PASSWORD } from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, FAILURE, LOGIN } = actionTypes

const ui = () => {
  const isUserAuthenticationDone = (state = false, action: actions) => {
    switch (action.type) {
      case LOGIN[SUCCESS]:
        return true
      case LOGIN[FAILURE]:
      case LOGIN[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isUserAuthenticationFailed = (state = false, action: actions) => {
    switch (action.type) {
      case LOGIN[SUCCESS]:
      case LOGIN[REQUEST]:
        return false
      case LOGIN[FAILURE]:
        return true
      default:
        return state
    }
  }

  const getClientKeys = (state = false, action: actions) => {
    switch (action.type) {
      case GET_CLIENT_KEYS[SUCCESS]:
        return true
      case GET_CLIENT_KEYS[REQUEST]:
        return false
      case GET_CLIENT_KEYS[FAILURE]:
        return false
      case GET_CLIENT_KEYS[RESET]:
        return false
      default:
        return state
    }
  }

  const getClientKeysFail = (state = false, action: actions) => {
    switch (action.type) {
      case GET_CLIENT_KEYS[SUCCESS]:
        return false
      case GET_CLIENT_KEYS[REQUEST]:
        return false
      case GET_CLIENT_KEYS[FAILURE]:
        return true
      case GET_CLIENT_KEYS[RESET]:
        return false
      default:
        return state
    }
  }

  const getForgotPassword = (state = false, action: actions) => {
    switch (action.type) {
      case FORGOT_PASSWORD[SUCCESS]:
        return true
      case FORGOT_PASSWORD[REQUEST]:
        return false
      case FORGOT_PASSWORD[FAILURE]:
        return false
      case FORGOT_PASSWORD[RESET]:
        return false
      default:
        return state
    }
  }

  const getResetPassword = (state = false, action: actions) => {
    switch (action.type) {
      case RESET_PASSWORD[SUCCESS]:
        return true
      case RESET_PASSWORD[REQUEST]:
        return false
      case RESET_PASSWORD[FAILURE]:
        return false
      case RESET_PASSWORD[RESET]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    isUserAuthenticationDone,
    isUserAuthenticationFailed,
    getClientKeys,
    getClientKeysFail,
    getForgotPassword,
    getResetPassword,
  })
}

export default ui

export const getUserAuthentication = (state: RootState) => state.ui.userAuthentication
