import { combineReducers } from 'redux'
import * as clientManagementState from './clientManagement'
import * as dashboardState from './dashboard'
import * as loaderState from './loaderState'
import * as moduleManagementState from './moduleManagement'
import * as refreshAccessTokenState from './refreshAccessToken'
import * as uploadImageState from './uploadImage'
import * as userAuthenticationState from './userAuthentication'
import * as userManagementState from './userManagement'

export {
  clientManagementState,
  dashboardState,
  loaderState,
  moduleManagementState,
  refreshAccessTokenState,
  uploadImageState,
  userAuthenticationState,
  userManagementState,
}

export default combineReducers({
  dashboard: dashboardState.default(),
  userAuthentication: userAuthenticationState.default(),
  refreshAccessToken: refreshAccessTokenState.default(),
  clientManagement: clientManagementState.default(),
  moduleManagement: moduleManagementState.default(),
  userManagement: userManagementState.default(),
  uploadImage: uploadImageState.default(),
  loaderState: loaderState.default(),
})
