import { combineReducers } from 'redux'
import entities, * as Entities from './entities'
import ui, * as UI from './ui'

export const dashboardEntity = Entities.dashboardState
export const userAuthenticationEntity = Entities.userAuthenticationState
export const refreshAccessTokenEntity = Entities.refreshAccessTokenState
export const clientManagementEntity = Entities.clientManagementState
export const moduleManagementEntity = Entities.moduleManagementState
export const userManagementEntity = Entities.userManagementState
export const uploadImageEntity = Entities.uploadImageState

export const dashboardUI = UI.dashboardState
export const userAuthenticationUI = UI.userAuthenticationState
export const refreshAccessTokenUI = UI.refreshAccessTokenState
export const clientManagementUI = UI.clientManagementState
export const moduleManagementUI = UI.moduleManagementState
export const userManagementUI = UI.userManagementState
export const uploadImageUI = UI.uploadImageState
export const loaderStateUI = UI.loaderState

export default () =>
  combineReducers({
    entities,
    ui,
  })
