import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, FAILURE, UPLOAD_IMAGE } = actionTypes

const ui = () => {
  const uploadImage = (state = false, action: actions) => {
    switch (action.type) {
      case UPLOAD_IMAGE[SUCCESS]:
        return true
      case UPLOAD_IMAGE[FAILURE]:
      case UPLOAD_IMAGE[REQUEST]:
        return false
      default:
        return state
    }
  }

  return combineReducers({
    uploadImage,
  })
}

export default ui

export const getUploadImage = (state: RootState) => state.ui.uploadImage
