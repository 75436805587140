import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import {
  ADD_NEW_USER,
  DELETE_USER,
  FAILURE,
  GET_USER_BY_ID,
  REMEMBER_USER_DETAILS,
  RESEND_RESET_PASSWORD_LINK,
  RESET,
  SAVE_MODULE_DATA_FOR_USER,
  UPDATE_USER,
} from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, GET_ALL_USER } = actionTypes

const entity = () => {
  const getAllUsers = (state = [], action: actions) => {
    switch (action.type) {
      case GET_ALL_USER[SUCCESS]:
        return action.payload
      case GET_ALL_USER[REQUEST]:
        return []
      default:
        return state
    }
  }

  const getUserById = (state = [], action: actions) => {
    switch (action.type) {
      case GET_USER_BY_ID[SUCCESS]:
        return action.payload
      case GET_USER_BY_ID[REQUEST]:
        return []
      case GET_USER_BY_ID[RESET]:
        return []
      default:
        return state
    }
  }

  const createUser = (state = [], action: actions) => {
    switch (action.type) {
      case ADD_NEW_USER[SUCCESS]:
        return action.payload
      case ADD_NEW_USER[REQUEST]:
        return []
      case ADD_NEW_USER[FAILURE]:
        return action.payload
      case ADD_NEW_USER[RESET]:
        return []
      default:
        return state
    }
  }

  const deleteUser = (state = false, action: actions) => {
    switch (action.type) {
      case DELETE_USER[SUCCESS]:
        return action.payload
      case DELETE_USER[FAILURE]:
        return action.payload
      case DELETE_USER[REQUEST]:
        return []
      case DELETE_USER[RESET]:
        return []
      default:
        return state
    }
  }

  const updateUser = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_USER[SUCCESS]:
        return action.payload
      case UPDATE_USER[REQUEST]:
        return []
      case UPDATE_USER[FAILURE]:
        return action.payload
      case UPDATE_USER[RESET]:
        return []
      default:
        return state
    }
  }

  const saveUserDetails = (state = [], action: actions) => {
    switch (action.type) {
      case REMEMBER_USER_DETAILS[REQUEST]:
        return action.payload
      case REMEMBER_USER_DETAILS[RESET]:
        return {}
      default:
        return state
    }
  }

  const saveModuleDetailsForUser = (state = [], action: actions) => {
    switch (action.type) {
      case SAVE_MODULE_DATA_FOR_USER[REQUEST]:
        return action.payload
      case SAVE_MODULE_DATA_FOR_USER[RESET]:
        return []
      default:
        return state
    }
  }

  const resendPasswordReset = (state = {}, action: actions) => {
    switch (action.type) {
      case RESEND_RESET_PASSWORD_LINK[REQUEST]:
        return {}
      case RESEND_RESET_PASSWORD_LINK[SUCCESS]:
        return action.payload
      case RESEND_RESET_PASSWORD_LINK[FAILURE]:
        return {}
      case RESEND_RESET_PASSWORD_LINK[RESET]:
        return {}
      default:
        return state
    }
  }

  return combineReducers({
    getAllUsers,
    createUser,
    deleteUser,
    getUserById,
    updateUser,
    saveUserDetails,
    saveModuleDetailsForUser,
    resendPasswordReset,
  })
}

export default entity

export const getUsers = (state: RootState) => state.entities.userManagement
