import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Dispatch } from 'redux'
import { createSubModule, saveSubModuleDetails, updateSubModule } from '../../actions'
import { RootState } from '../../configureStore'
import { moduleManagementEntity } from '../../reducers'
import {
  ColumnStack,
  FullWidthStack,
  MandatoryMark,
  StyledLable,
  StyledTextField,
} from '../Common/CommonStyles'
import DragDrop from '../Common/DragDrop'
import StyledBreadCrumb from '../Common/StyledBreadCrumb'
import { MainContainer, SubModuleHeader } from './ModuleManagementComponents'
import { ModuleUpdate, NewModuleSave, SubModuleCardData } from './ModuleManagementTypes'

/* eslint-disable */

interface AddSubModuleProps {
  createSubModule: (data: NewModuleSave[]) => void
  updateSubModule: (data: SubModuleCardData) => void
  subModules: NewModuleSave[]
  savedSubModule: SubModuleCardData
  subModuleUpdated: SubModuleCardData
  savedModuleName: ModuleUpdate
  resetSavedSubModule: () => void
  resetSubModule: () => void
  saveSubModuleDetails: (data: ModuleUpdate) => void
}

const AddNewSubModule = (props: AddSubModuleProps) => {
  const {
    createSubModule,
    subModules,
    resetSavedSubModule,
    savedSubModule,
    subModuleUpdated,
    resetSubModule,
    updateSubModule,
    saveSubModuleDetails,
  } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [subModuleName, setSubModuleName] = useState('')
  const [moduleNameBeforeUpdate, setModuleNameBeforeUpdate] = useState('')
  const [submModuleURL, setSubModuleURL] = useState('')
  const [moduleCoverImage, setModuleCoverImage] = useState('')
  const [submitClicked, setSubmitClick] = useState(false)

  //This useeffect will creal all saved data when we leave component
  useEffect(() => {
    const savedData = localStorage.getItem('subModuleObject')
    if (savedData) {
      saveSubModuleDetails(JSON.parse(savedData))
    }
    localStorage.removeItem('subModuleObject')
    return () => {
      localStorage.removeItem('subModuleObject')
      resetSavedSubModule()
    }
  }, [])

  useEffect(() => {
    const warnRefresh = (event: BeforeUnloadEvent) => {
      if (
        Object.keys(savedSubModule).length > 1 &&
        (savedSubModule.name !== subModuleName ||
          savedSubModule.link !== submModuleURL ||
          savedSubModule.link !== moduleCoverImage)
      ) {
        localStorage.setItem('subModuleObject', JSON.stringify(savedSubModule))
        event.preventDefault()
        event.stopPropagation()
        event.returnValue = ''
        return 'Do you want to refresh?'
      } else if (subModuleName !== '' || submModuleURL !== '' || moduleCoverImage !== '') {
        event.preventDefault()
        event.stopPropagation()
        event.returnValue = ''
        return 'Do you want to refresh?'
      }
    }
    //Adding event listner to warn user before refresh
    window.addEventListener('beforeunload', warnRefresh)
    return () => {
      window.removeEventListener('beforeunload', warnRefresh)
    }
  }, [savedSubModule, subModuleName, submModuleURL, moduleCoverImage])

  //This useEffect will set Data in case we edit a submodule
  useEffect(() => {
    if (Array.isArray(savedSubModule) ? savedSubModule.length > 0 : savedSubModule) {
      setSubModuleName(savedSubModule.name)
      setModuleNameBeforeUpdate(savedSubModule.name)
      setSubModuleURL(savedSubModule.link)
      setModuleCoverImage(savedSubModule.logoImagePath)
    }
  }, [savedSubModule])

  //This will work after successful update of submodule and navigate you back to previous page
  useEffect(() => {
    if (subModuleUpdated && subModuleUpdated.docId) {
      toast.success(t('sub_module_update_message'))
      navigate(-1)
      resetSubModule()
    }
  }, [JSON.stringify(subModuleUpdated)])

  //This function handle drag and drop image
  const handleDrop = (imageUrl: string) => {
    setModuleCoverImage(imageUrl)
  }

  const handleCreateSubModule = () => {
    let subModule
    if (!savedSubModule || (Array.isArray(savedSubModule) && savedSubModule.length === 0)) {
      let subModuleObject = {
        visible: true,
        activeFlag: true,
        createdBy: 'system',
        lastModifiedBy: 'system',
        name: subModuleName,
        link: submModuleURL,
        logoImagePath: moduleCoverImage,
        isAssigned: false,
      }
      if (subModules.length > 0) {
        subModule = [...subModules, subModuleObject]
      } else {
        subModule = [subModuleObject]
      }
      createSubModule([...subModule])
      navigate(-1)
    } else if (savedSubModule.moduleId && savedSubModule.code) {
      handleSubModuleUpdate()
    } else {
      const updatedSubModules = subModules?.map((subModule: NewModuleSave) => {
        if (subModule.name === moduleNameBeforeUpdate) {
          return {
            ...subModule,
            name: subModuleName,
            link: submModuleURL,
            logoImagePath: moduleCoverImage,
          }
        }
        return subModule
      })
      createSubModule([...updatedSubModules])
      navigate(-1)
    }
  }

  //This function will check validation for all input fields
  const checkModuleValidation = () => {
    setSubmitClick(true)
    if (subModuleName === '') toast.error(t('sub_module_required'))
    else if (submModuleURL === '') toast.error(t('sub_module_url_required'))
    else if (moduleCoverImage === '') toast.error(t('sub_Module_image_required'))
    else handleCreateSubModule()
  }

  //This function responsible to update submodule
  const handleSubModuleUpdate = () => {
    savedSubModule.name = subModuleName
    savedSubModule.link = submModuleURL
    savedSubModule.logoImagePath = moduleCoverImage
    updateSubModule(savedSubModule)
  }
  //Handle Submodule name change
  const handleSubModuleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubModuleName(e.target.value?.trimStart())
  }
  //Handle Submodule URL change
  const handleSubModuleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubModuleURL(e.target.value?.trimStart())
  }

  //This function trigger when we click back from breadcrumb
  const handleNavigateBack = () => {
    resetSavedSubModule()
  }

  return (
    <MainContainer>
      <StyledBreadCrumb handleDone={checkModuleValidation} handleBack={handleNavigateBack} />
      <FullWidthStack sx={SubModuleHeader}>
        <ColumnStack width={'35%'} height={'100%'}>
          <FullWidthStack>
            <StyledLable>{t('submodule_name')}</StyledLable>
            <MandatoryMark>*</MandatoryMark>
          </FullWidthStack>
          <StyledTextField
            id='outlined-size-small'
            size='small'
            value={subModuleName}
            onChange={handleSubModuleNameChange}
            error={submitClicked && subModuleName === ''}
            placeholder={`${t('enter_module_name')}`}
          />
          <FullWidthStack>
            <StyledLable>{t('external_sub_module_url')}</StyledLable>
            <MandatoryMark>*</MandatoryMark>
          </FullWidthStack>
          <StyledTextField
            id='outlined-size-small'
            size='small'
            value={submModuleURL}
            onChange={handleSubModuleURLChange}
            error={submitClicked && submModuleURL === ''}
            placeholder={`${t('enter_module_url')}`}
          />
        </ColumnStack>
        <ColumnStack width={'50%'} height={'100%'}>
          <FullWidthStack>
            <StyledLable>{t('submodule_cover_image')}</StyledLable>
            <MandatoryMark>* </MandatoryMark>
            <Typography color={'gray'} fontSize={'10px'} ml={'5px'}>
              {t('logo_error')}
            </Typography>
          </FullWidthStack>
          <FullWidthStack mt={'5px'}>
            <DndProvider backend={HTML5Backend}>
              <DragDrop onDrop={handleDrop} coverImageUrl={moduleCoverImage} />
            </DndProvider>
          </FullWidthStack>
        </ColumnStack>
      </FullWidthStack>
    </MainContainer>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    savedSubModule: moduleManagementEntity.getModules(state).saveSubModuleDetails,
    subModules: moduleManagementEntity.getModules(state).createSubModule,
    subModuleUpdated: moduleManagementEntity.getModules(state).updateSubModule,
    savedModuleName: moduleManagementEntity.getModules(state).saveModuleName,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetSavedSubModule: () => dispatch(saveSubModuleDetails.reset()),
    createSubModule: (data: NewModuleSave[]) => dispatch(createSubModule.request(data)),
    updateSubModule: (data: SubModuleCardData) => dispatch(updateSubModule.request(data)),
    resetSubModule: () => dispatch(updateSubModule.reset()),
    saveSubModuleDetails: (data: ModuleUpdate) => dispatch(saveSubModuleDetails.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddNewSubModule)
