import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { FAILURE, GET_ACCESS_TOKEN, RESET } from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, REFRESH_ACCESS_TOKEN } = actionTypes

const entity = () => {
  const getRefreshAccessToken = (state = {}, action: actions) => {
    switch (action.type) {
      case REFRESH_ACCESS_TOKEN[SUCCESS]:
        return action.payload
      case REFRESH_ACCESS_TOKEN[REQUEST]:
        return {}
      default:
        return state
    }
  }

  const isRefreshTokenDone = (state = {}, action: actions) => {
    switch (action.type) {
      case GET_ACCESS_TOKEN[SUCCESS]:
        return action.payload
      case GET_ACCESS_TOKEN[FAILURE]:
        return action.payload
      case GET_ACCESS_TOKEN[RESET]:
        return {}
      default:
        return state
    }
  }

  return combineReducers({
    getRefreshAccessToken,
    isRefreshTokenDone,
  })
}

export default entity

export const getRefreshAccessToken = (state: RootState) => state.entities.refreshAccessToken
