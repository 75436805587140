import { Box, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { createSubModule, deleteSubModule, saveSubModuleDetails } from '../../actions'
import BackImage from '../../assets/images/Mask.jpg'
import { RootState } from '../../configureStore'
import { moduleManagementEntity } from '../../reducers'
import { StyledSubModuleIcon, StyledSubModuleIconButton } from '../Common/CommonStyles'
import StyledPopper from '../Common/StyledPopper'
import { StyledCard, WhiteMoreHorizIcon } from '../Module Management/ModuleManagementComponents'
import { ModuleUpdate, NewModuleSave, SubModuleDelete } from './ModuleManagementTypes'

interface SubModuleCardsTypes {
  cardData: ModuleUpdate
  isActive?: boolean
  deleteSubModule: (data: SubModuleDelete) => void
  parentModule: ModuleUpdate | NewModuleSave
  createSubModule: (data: NewModuleSave[]) => void
  saveSubModuleDetails: (data: ModuleUpdate) => void
  addSubModuleButton?: boolean
  includeButton?: boolean
  subModules: ModuleUpdate[]
}

const SubModuleCards = (props: SubModuleCardsTypes) => {
  const {
    cardData,
    isActive,
    addSubModuleButton,
    deleteSubModule,
    saveSubModuleDetails,
    parentModule,
    subModules,
    createSubModule,
  } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const checked = isActive === false ? false : true
  const navigate = useNavigate()

  //This function will open popper menu while hover on more icon
  const handleOpenMenu = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
  }

  //This function will close popper menu while mouse out from more icon
  const handleCloseMenu = () => {
    setTimeout(() => {
      setAnchorEl(null)
    }, 200)
  }

  //This function handles and detects click of popper menu
  const handleMenuClick = (buttonValue: number) => {
    handleCloseMenu()
    switch (buttonValue) {
      case 1:
        saveSubModuleDetails(cardData)
        navigate(`./${cardData.name}`)
        //Handle edit click
        break
      case 2:
        //Handle delete click
        if (parentModule.docId && cardData.docId) {
          deleteSubModule({
            subModuleId: cardData.docId ? cardData.docId : '',
            moduleId: parentModule.docId,
          })
        } else {
          const filteredObjects = subModules.filter(
            (obj: { name: string }) => obj.name !== cardData.name,
          )
          createSubModule([...filteredObjects])
        }
        break
    }
  }
  return (
    <StyledCard
      // key={props.key}
      variant='outlined'
      image={
        cardData?.logoImagePath && cardData?.logoImagePath !== ''
          ? cardData.logoImagePath
          : BackImage
      }
      // isActive={isActive === false ? false : true}
      isactive={checked}
      height={'110px'}
      onError={(e) => {
        const target = e.target as HTMLImageElement
        target.onerror = null
        target.src = BackImage
      }}
    >
      <Stack direction={'column'} height={'100%'} justifyContent={'space-between'}>
        <Box display={'flex'} flexDirection={'row-reverse'} width={'100%'} />
        <Stack justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
          <Stack flexDirection={'row'}>
            <Typography color={'white'} variant={'body2'}>
              {cardData?.name}
            </Typography>
          </Stack>
          {addSubModuleButton && checked ? (
            <>
              <StyledSubModuleIconButton>
                <StyledSubModuleIcon />
              </StyledSubModuleIconButton>
            </>
          ) : addSubModuleButton ? (
            ''
          ) : (
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              classes={{ root: 'dot-box' }}
            >
              <Box onMouseOver={handleOpenMenu} onMouseLeave={handleCloseMenu}>
                <WhiteMoreHorizIcon />
                {cardData.activeFlag ? (
                  <StyledPopper anchorEl={anchorEl} handleClick={handleMenuClick} />
                ) : null}
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>
    </StyledCard>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    subModules: moduleManagementEntity.getModules(state).createSubModule,
    savedModuleName: moduleManagementEntity.getModules(state).saveModuleName,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    saveSubModuleDetails: (data: ModuleUpdate) => dispatch(saveSubModuleDetails.request(data)),
    deleteSubModule: (data: SubModuleDelete) => dispatch(deleteSubModule.request(data)),
    createSubModule: (data: NewModuleSave[]) => dispatch(createSubModule.request(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SubModuleCards)
