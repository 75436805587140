import NotFoundImage from '../../assets/images/notFound.png'
import { FullWidthStack } from '../Common/CommonStyles'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { NavigateButton } from './Styles'

const NotFound = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <FullWidthStack justifyContent={'center'} mt={'5%'} direction={'column'}>
      <img src={NotFoundImage} alt='' height={'400px'} />
      <NavigateButton onClick={() => navigate('/')}>{t('back_to_home')}</NavigateButton>
    </FullWidthStack>
  )
}

export default NotFound
