import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageDropdown from '../Common/LanguageDropdown'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Heading,
  LangDrop,
  LeftContainer,
  LoginButton,
  Logo,
  MainContainer,
  RightContainer,
} from './Styles'
import { connect, useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ColumnStack } from '../Common/CommonStyles'
import {
  expiredToken,
  extractEmailFromToken,
  getTokenFromURL,
  saltHashedPassword,
  validatePassword,
} from '../../utils'
import banner from '../../assets/images/uniqus_bannerv2.png'
import { userAuthenticationUI } from '../../reducers'
import { getResetPassword } from '../../actions'
import { useNavigate } from 'react-router-dom'

type ChangePasswordPropType = {}

const ChangePassword = (props: ChangePasswordPropType) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [isTokenExpired, setIsTokenExpired] = useState(false)
  const tokenURL = getTokenFromURL()

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handlePasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleConfirmPasswordInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value)
  }

  const hashedPass = (password: string) => {
    const email = extractEmailFromToken(tokenURL)
    const hashSaltedPass = saltHashedPassword(password, email)
    return hashSaltedPass
  }

  useEffect(() => {
    const tokenExpired = expiredToken()
    if (tokenExpired) {
      setIsTokenExpired(true)
    } else {
      setIsTokenExpired(false)
    }
  }, [])

  const passwordChanged = useSelector(
    (state: any) => userAuthenticationUI.getUserAuthentication(state).getResetPassword,
  )

  const handleResetPassword = () => {
    dispatch(getResetPassword.request({ newPassword: hashedPass(password) }))
  }

  useEffect(() => {
    if (passwordChanged) {
      toast.success(t('changed_password_message'))
      navigate('/')
    }
  }, [passwordChanged])

  const handleSubmit = () => {
    if (password === '') {
      toast.error(t('fill_both_username_and_password'))
    } else {
      const validationDetails = validatePassword(password)
      if (!validationDetails?.status) {
        toast.error(t(validationDetails.message))
        return
      } else if (confirmPassword.trim() === '') {
        toast.error(t('confirm_pass_null'))
        return
      } else if (password !== confirmPassword) {
        toast.error(t('confirm_pass_error'))
        return
      }
      handleResetPassword()
    }
  }

  const handlePassword = () => {
    navigate('/forgot-password')
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <MainContainer>
      <LeftContainer src={banner}></LeftContainer>
      <RightContainer>
        <LangDrop>
          <LanguageDropdown />
        </LangDrop>
        <Logo src='./images/logo.png'></Logo>
        {isTokenExpired ? (
          <div>
            <Heading fontsize={'18px'}>{t('link_expired')}</Heading>
            <Typography fontSize={'13px'} color={'#000'} marginBottom={'25px'}>
              {t('expired_token_message')}
            </Typography>
            <LoginButton fullWidth onClick={handlePassword}>
              {t('navigate_forgot_password')}
            </LoginButton>
          </div>
        ) : (
          <div>
            <Heading fontsize={'26px'}>{t('change_password')}</Heading>
            <Typography fontSize={'12px'} color={'#999999'}>
              {t('change_pass_msg')}
            </Typography>
            <ColumnStack gap={2} mt={2} width={'100%'} mb={3}>
              <FormControl variant='outlined'>
                <InputLabel required htmlFor='outlined-adornment-password'>
                  {t('new_password')}
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  type={showPassword ? 'text' : 'password'}
                  onKeyPress={handleKeyPress}
                  onChange={handlePasswordInput}
                  value={password}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='New Password *'
                />
              </FormControl>
              <FormControl variant='outlined'>
                <InputLabel required htmlFor='outlined-adornment-password'>
                  {t('confirm_password')}
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-confirm-password'
                  type='password'
                  onKeyPress={handleKeyPress}
                  onChange={handleConfirmPasswordInput}
                  value={confirmPassword}
                  label='Confirm Password  * '
                />
              </FormControl>
            </ColumnStack>
            <LoginButton fullWidth onClick={handleSubmit}>
              {t('change_password')}
            </LoginButton>
          </div>
        )}
      </RightContainer>
    </MainContainer>
  )
}

export default ChangePassword
