import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  StyledHTMLImg,
  DeletePopupButton,
  DeleteCancelPopupButton,
  DialogTitleSx,
  DialogContentSx,
  DialogActionSx,
} from './CommonStyles'
import deleteImg from '../../assets/images/Delete.png'
import PropTypes from 'prop-types'

export type deletePopupType = {
  open: boolean
  triggerAction: (action: string) => void
}
const DeletePopup = (props: deletePopupType) => {
  const { open, triggerAction } = props
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        sx: {
          borderRadius: '10px',
        },
      }}
    >
      <DialogTitle id='alert-dialog-title' sx={DialogTitleSx}>
        <Typography variant='h6'>{t('are_you_sure_you_want_to_delete')}</Typography>
      </DialogTitle>
      <DialogContent sx={DialogContentSx}>
        <StyledHTMLImg src={deleteImg} height='150px'></StyledHTMLImg>
      </DialogContent>
      <DialogActions sx={DialogActionSx}>
        <DeleteCancelPopupButton
          onClick={() => {
            triggerAction('cancel')
          }}
          variant='outlined'
          size='small'
        >
          {t('cancel')}
        </DeleteCancelPopupButton>
        <DeletePopupButton
          onClick={() => {
            triggerAction('delete')
          }}
          variant='contained'
          size='small'
        >
          {t('delete')}
        </DeletePopupButton>
      </DialogActions>
    </Dialog>
  )
}

DeletePopup.prototype = {
  open: PropTypes.bool,
  triggerAction: PropTypes.func.isRequired,
}

export default DeletePopup
