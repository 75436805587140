import { Avatar } from '@mui/material'
import { useEffect, useState } from 'react'
import { StyledHTMLImg } from '../Common/CommonStyles'
import { AvatarStyles } from './ClientsStyles'

type BrokenImageHandlerType = {
  data: any
  height?: string | number
  width?: string | number
  avtarStyle?: { [key: string]: string | number }
  margin?: string
}

const BrokenImageHandler = (props: BrokenImageHandlerType) => {
  const { data, height, width, avtarStyle, margin } = props
  const [imageError, setImageError] = useState(false)

  const handleImageError = () => {
    setImageError(true)
  }

  useEffect(() => {
    if (data?.logo === null) {
      setImageError(true)
    }
  }, [data])

  return (
    <>
      {imageError ? (
        <Avatar sx={AvatarStyles(avtarStyle)}>{data?.company_name?.slice(0, 1)}</Avatar>
      ) : (
        <StyledHTMLImg
          src={data?.logo}
          alt='logo'
          width={width ? width : '30px'}
          margin={margin ?? '0px 10px 0px 0px'}
          onError={handleImageError}
          {...(height ? { height } : {})}
        />
      )}
    </>
  )
}

export default BrokenImageHandler
