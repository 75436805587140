import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import {
  CREATE_MODULE,
  CREATE_SUB_MODULE,
  DELETE_MODULE,
  DELETE_SUB_MODULE,
  RESET,
  UPDATE_MODULE,
  UPDATE_SUB_MODULE,
} from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, FAILURE, GET_ALL_MODULES } = actionTypes

const ui = () => {
  const isGetAllModuleDone = (state = false, action: actions) => {
    switch (action.type) {
      case GET_ALL_MODULES[SUCCESS]:
        return true
      case GET_ALL_MODULES[FAILURE]:
      case GET_ALL_MODULES[REQUEST]:
        return false
      default:
        return state
    }
  }

  const createModule = (state = [], action: actions) => {
    switch (action.type) {
      case CREATE_MODULE[SUCCESS]:
        return true
      case CREATE_MODULE[REQUEST]:
        return []
      case CREATE_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  const createSubModule = (state = [], action: actions) => {
    switch (action.type) {
      case CREATE_SUB_MODULE[SUCCESS]:
        return action.payload
      case CREATE_SUB_MODULE[REQUEST]:
        return action.payload
      case CREATE_SUB_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  const deleteModule = (state = false, action: actions) => {
    switch (action.type) {
      case DELETE_MODULE[SUCCESS]:
        return true
      case DELETE_MODULE[FAILURE]:
      case DELETE_MODULE[REQUEST]:
        return false
      default:
        return state
    }
  }

  const updateModule = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_MODULE[SUCCESS]:
        return true
      case UPDATE_MODULE[REQUEST]:
        return []
      case UPDATE_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  const deleteSubModule = (state = false, action: actions) => {
    switch (action.type) {
      case DELETE_SUB_MODULE[SUCCESS]:
        return true
      case DELETE_SUB_MODULE[FAILURE]:
      case DELETE_SUB_MODULE[REQUEST]:
        return false
      case DELETE_SUB_MODULE[RESET]:
        return false
      default:
        return state
    }
  }

  const updateSubModule = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_SUB_MODULE[SUCCESS]:
        return true
      case UPDATE_SUB_MODULE[REQUEST]:
        return []
      case UPDATE_SUB_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    isGetAllModuleDone,
    createModule,
    createSubModule,
    deleteModule,
    updateModule,
    deleteSubModule,
    updateSubModule,
  })
}

export default ui

export const getAllModule = (state: RootState) => state.ui.moduleManagement
