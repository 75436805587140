import React from 'react'
import { useTranslation } from 'react-i18next'
import bcrypt from 'bcryptjs'
import { MD5 } from 'crypto-js'
import { fixedSaltValue } from './staticData'

export const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
  const target = event.target as HTMLImageElement
  target.src = './images/Mask.jpg'
}

export const Primary = '#A90076'

export const Secondary = '#F2D5F2'

export const TextColor = '#0D0D0D'

export const SecondaryV2 = '#4E2973'

export const Background = '#F8F9FF'

export const SecondaryButtonColor = '#818E94'

export const NameLimit = 50

export const DesignationLimit = 30

export const EmailLimit = 60

export const PasswordLimit = 25

export const CompanyNameLimit = 60

//Setting limit in text field
export const setLimit = (event: React.ChangeEvent<HTMLInputElement>, maxLength: number): string => {
  const { value } = event?.target
  event.target.value = value.slice(0, maxLength)
  return event?.target?.value?.trimStart()
}

//Password Validation
export function validatePassword(password: string): { status: boolean; message: string } {
  // Password must be at least 8 characters long
  if (password.length < 6) {
    return { status: false, message: 'char_limit_message' }
  }
  // Password must contain at least one uppercase letter, one lowercase letter, and one digit
  const hasUppercase = /[A-Z]/.test(password)
  const hasLowercase = /[a-z]/.test(password)
  const hasDigit = /\d/.test(password)
  if (!hasUppercase) {
    return { status: false, message: 'uppercase_message' }
  }
  if (!hasLowercase) {
    return { status: false, message: 'lowercase_message' }
  }
  if (!hasDigit) {
    return { status: false, message: 'digit_message' }
  }

  // Password is valid
  return { status: true, message: '' }
}

// To Fetch Token From URL
export const getTokenFromURL = (): any => {
  const tokenSearch = new URLSearchParams(window.location.search)
  return tokenSearch.get('token')
}

//Extract User Email from token
export const extractEmailFromToken = (token: string) => {
  try {
    const userEmail = token?.split('.')
    const decodedPayload = atob(userEmail[1])
    const payloadData = JSON.parse(decodedPayload)
    return payloadData.sub
  } catch (error) {
    console.error('error decoding error', error)
    return null
  }
}

//To verify expired token for reset password screen
export const expiredToken = () => {
  const token = getTokenFromURL()
  if (token) {
    const tokenParts = token.split('.');
    if (tokenParts.length === 3) {
      const payload = JSON.parse(atob(tokenParts[1]));
      const expirationTime = payload.exp * 1000;
      const currentTime = new Date().getTime();
      return currentTime > expirationTime
    }
  }
}

// Password Hashing and Salt using bcrypt and crypto
export const saltHashedPassword = (password: string, email: string): string => {
  const hashedEmail = MD5(email).toString()
  const fixedSalt = fixedSaltValue + hashedEmail.slice(0, 22)
  const hashedPassword = bcrypt.hashSync(password, fixedSalt)
  return hashedPassword
}

export const BaseURLAdmin = `https://grc-dev-api.uniqus.com/admin/`
export const AuthBaseURL = `https://grc-dev-api.uniqus.com/auth/`
