import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import {
  CREATE_MODULE,
  CREATE_SUB_MODULE,
  DELETE_MODULE,
  DELETE_SUB_MODULE,
  FAILURE,
  GET_MODULE_BY_ID,
  REMEMBER_MODULE_NAME,
  RESET,
  SAVE_SUB_MODULE,
  UPDATE_MODULE,
  UPDATE_SUB_MODULE,
} from '../../actions/actiontypes'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, GET_ALL_MODULES } = actionTypes

const entity = () => {
  const getAllModules = (state = [], action: actions) => {
    switch (action.type) {
      case GET_ALL_MODULES[SUCCESS]:
        return action.payload
      default:
        return state
    }
  }

  const getModuleById = (state = [], action: actions) => {
    switch (action.type) {
      case GET_MODULE_BY_ID[SUCCESS]:
        return action.payload
      case GET_MODULE_BY_ID[REQUEST]:
        return []
      case GET_MODULE_BY_ID[RESET]:
        return []
      default:
        return state
    }
  }

  const createModule = (state = [], action: actions) => {
    switch (action.type) {
      case CREATE_MODULE[SUCCESS]:
        return action.payload
      case CREATE_MODULE[REQUEST]:
        return []
      case CREATE_MODULE[FAILURE]:
        return action.payload
      case CREATE_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  const createSubModule = (state = [], action: actions) => {
    switch (action.type) {
      case CREATE_SUB_MODULE[SUCCESS]:
        return action.payload
      case CREATE_SUB_MODULE[REQUEST]:
        return action.payload
      case CREATE_SUB_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  const saveModuleName = (state = [], action: actions) => {
    switch (action.type) {
      case REMEMBER_MODULE_NAME[REQUEST]:
        return action.payload
      case REMEMBER_MODULE_NAME[RESET]:
        return ''
      default:
        return state
    }
  }

  const deleteModule = (state = false, action: actions) => {
    switch (action.type) {
      case DELETE_MODULE[SUCCESS]:
        return action.payload
      case DELETE_MODULE[FAILURE]:
        return action.payload
      case DELETE_MODULE[REQUEST]:
        return []
      case DELETE_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  const updateModule = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_MODULE[SUCCESS]:
        return action.payload
      case UPDATE_MODULE[REQUEST]:
        return []
      case UPDATE_MODULE[FAILURE]:
        return action.payload
      case UPDATE_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  const deleteSubModule = (state = false, action: actions) => {
    switch (action.type) {
      case DELETE_SUB_MODULE[SUCCESS]:
        return action.payload
      case DELETE_SUB_MODULE[FAILURE]:
        return action.payload
      case DELETE_SUB_MODULE[REQUEST]:
        return []
      case DELETE_SUB_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  const saveSubModuleDetails = (state = [], action: actions) => {
    switch (action.type) {
      case SAVE_SUB_MODULE[REQUEST]:
        return action.payload
      case SAVE_SUB_MODULE[RESET]:
        return ''
      default:
        return state
    }
  }

  const updateSubModule = (state = [], action: actions) => {
    switch (action.type) {
      case UPDATE_SUB_MODULE[SUCCESS]:
        return action.payload
      case UPDATE_SUB_MODULE[REQUEST]:
        return []
      case UPDATE_SUB_MODULE[FAILURE]:
        return action.payload
      case UPDATE_SUB_MODULE[RESET]:
        return []
      default:
        return state
    }
  }

  return combineReducers({
    getAllModules,
    createModule,
    createSubModule,
    saveModuleName,
    deleteModule,
    getModuleById,
    updateModule,
    deleteSubModule,
    saveSubModuleDetails,
    updateSubModule,
  })
}

export default entity

export const getModules = (state: RootState) => state.entities.moduleManagement
