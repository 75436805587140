import { all } from 'redux-saga/effects'
import { sagas as clientManagement } from './clientManagement'
import { sagas as dashboardSagas } from './dashboard'
import { sagas as moduleManagement } from './moduleManagementSaga'
import { sagas as refreshAccessToken } from './refreshAccessToken'
import { sagas as uploadImage } from './uploadImage'
import { sagas as userAuthentication } from './userAuthentication'
import { sagas as userManagement } from './userManagement'

export default function* rootSaga() {
  yield all({
    ...dashboardSagas,
    ...userAuthentication,
    ...refreshAccessToken,
    ...clientManagement,
    ...uploadImage,
    ...moduleManagement,
    ...userManagement,
  })
}
