import { combineReducers } from 'redux'
import { actionTypes } from '../../actions'
import { actions } from '../../actions/Types'
import { RootState } from '../../configureStore'

const { SUCCESS, REQUEST, FAILURE, REFRESH_ACCESS_TOKEN } = actionTypes

const ui = () => {
  const isRefreshAccessTokenDone = (state = false, action: actions) => {
    switch (action.type) {
      case REFRESH_ACCESS_TOKEN[SUCCESS]:
        return true
      case REFRESH_ACCESS_TOKEN[FAILURE]:
      case REFRESH_ACCESS_TOKEN[REQUEST]:
        return false
      default:
        return state
    }
  }

  const isRefreshAccessTokenFailed = (state = false, action: actions) => {
    switch (action.type) {
      case REFRESH_ACCESS_TOKEN[SUCCESS]:
      case REFRESH_ACCESS_TOKEN[REQUEST]:
        return false
      case REFRESH_ACCESS_TOKEN[FAILURE]:
        return true
      default:
        return state
    }
  }

  return combineReducers({
    isRefreshAccessTokenDone,
    isRefreshAccessTokenFailed,
  })
}

export default ui

export const getRefreshAccessToken = (state: RootState) => state.ui.refreshAccessToken
