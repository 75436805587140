import styled from '@emotion/styled'
import SearchIcon from '@mui/icons-material/Search'
import {
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Card,
  InputBase,
  Button,
  Box,
  Select,
  InputLabel,
  AccordionDetails,
} from '@mui/material'

const TableCard = styled(Card)({
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: '8px',
  padding: '20px',
})

const TypeToSearch = styled(Paper)({
  backgroundColor: '#F3F3F3',
  width: '22%',
  padding: '5px 0px 5px 10px',
  borderRadius: '8px',
})

const StyledSearchIcon = styled(SearchIcon)({
  verticalAlign: 'middle',
  color: '#797979',
})

const StyledSearchInputBase = styled(InputBase)({
  marginLeft: 4,
  flex: 1,
  '& input': { fontSize: '14px', height: '100%' },
})

const StyledTableHeaderRow = styled(TableRow)({
  '&:last-child td, &:last-child th': { border: 0 },
})

const StyledTableHeader = styled(TableHead)({
  backgroundColor: '#A90076',
})

const StyledTableHeaderCellFirst = styled(TableCell)({
  color: '#FFFFFF',
  borderRadius: '8px 0px 0px 8px',
})

const StyledTableHeaderCellLast = styled(TableCell)({
  color: '#FFFFFF',
  borderRadius: '0px 8px 8px 0px',
})

const StyledTableHeaderCell = styled(TableCell)({
  color: '#FFFFFF',
})

const SortByButton = styled(Button)({
  width: '130px',
  backgroundColor: '#F6F7F7',
  fontSize: '18px',
  color: '#000000',
  borderColor: '#86868633',
  height: '38px',
  '&:hover': {
    color: '#000000',
    borderColor: '#86868633',
    backgroundColor: '#F6F7F7',
  },
})

const FilterButton = styled(Button)({
  width: '130px',
  height: '40px',
  borderRadius: '10px',
  fontSize: '15px',
  display: 'flex',
  gap: '10px',
})

const SummeryBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '20px 20px',
})
const SummeryBoxContent = styled(Box)({
  width: '50%',
  display: 'flex',
  gap: '30px',
  alignItems: 'center',
})

const DetailsBox = styled(Box)({
  alignContent: 'center',
  display: 'flex',
  gap: '20px',
  flexWrap: 'wrap',
})

const CustomeSelect = styled(Select)({
  width: '100%',
  height: '40px',
  borderRadius: '6px',
})

const ContentBox = styled(Box)({
  width: '200px',
})

const CustomeInputLable = styled(InputLabel)({
  fontSize: '16px',
  fontWeight: '800',
})

const CustomeAccordionDetails = styled(AccordionDetails)({
  padding: '30px 30px',
})

export {
  TableCard,
  TypeToSearch,
  StyledSearchIcon,
  StyledSearchInputBase,
  SortByButton,
  StyledTableHeaderCell,
  StyledTableHeaderCellLast,
  StyledTableHeaderCellFirst,
  StyledTableHeader,
  StyledTableHeaderRow,
  FilterButton,
  SummeryBox,
  SummeryBoxContent,
  DetailsBox,
  CustomeSelect,
  ContentBox,
  CustomeInputLable,
  CustomeAccordionDetails,
}
